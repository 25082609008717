// src/SceneContent.js

import React, { useRef, useState, useEffect } from 'react';
import * as THREE from 'three';
import { useFrame, useThree } from '@react-three/fiber';
import { MapControls } from '@react-three/drei';
import GridComponent from './components/UI/GridComponent';
import GridOverlay from './components/UI/GridOverlay';
import CreatingIcon from './components/UI/CreatingIcon';
import EncryptionAnimation from './components/Animations/EncryptionAnimation';
import AuthenticationAnimation from './components/Animations/AuthenticationAnimation';

const SceneContent = ({
  icons,
  setIcons,
  onIconClick,
  connections,
  setConnections,
  selectedIcon,
  menuOpenIconId,
  onRename,
  onStartMove,
  onStartConnect,
  onStartChange, // Added this line
  movingIconId,
  onMoveEnd,
  setIconPosition,
  onConnectionClick,
  menuOpenConnectionId,
  onDeleteConnection,
  onEditConnection,
  isCurved,
  onMenuToggle,
  creatingIcon,
  setCreatingIcon,
  showConnectionLabels,
  presentationMode,
  currentWorkflow,
  currentStepIndex,
  setCurrentStepIndex,
  isPlaying,
  setIsPlaying,
  viewMode,
}) => {
  const controlsRef = useRef();
  const { camera, gl } = useThree();

  // Store default camera position and quaternion
  const defaultCameraPosition = useRef(new THREE.Vector3());
  const defaultCameraQuaternion = useRef(new THREE.Quaternion());
  const presentationStartPosition = useRef(new THREE.Vector3());
  const presentationTargetPosition = useRef(new THREE.Vector3());
  const presentationAnimationProgress = useRef(0);
  const presentationAnimating = useRef(false);
  const presentationStartQuaternion = useRef(new THREE.Quaternion());
  const presentationTargetQuaternion = useRef(new THREE.Quaternion());
  const presentationStartZoom = useRef(camera.zoom);
  const presentationTargetZoom = useRef(camera.zoom);

  const presentationTimeoutId = useRef(null);
  const presentationOnComplete = useRef(null);

  const [highlightedElements, setHighlightedElements] = useState([]);
  const [controlsTarget, setControlsTarget] = useState(
    new THREE.Vector3(0, 0, 0)
  );

  const isInitialMount = useRef(true); // Added to prevent initial camera animation

  // New state to manage active animations
  const [activeAnimations, setActiveAnimations] = useState([]);

  // Initialize camera and controls on mount
  useEffect(() => {
    camera.position.set(30, 35, 30);
    camera.up.set(0, 1, 0); // Ensure Y-axis is up
    camera.lookAt(0, 0, 0);

    // Store default camera position and quaternion
    defaultCameraPosition.current.copy(camera.position);
    defaultCameraQuaternion.current.copy(camera.quaternion);
    presentationStartZoom.current = camera.zoom;

    if (controlsRef.current) {
      controlsRef.current.target.set(0, 0, 0);
      controlsRef.current.update();
      controlsRef.current.saveState();
    }
  }, [camera]);

  // Handle view mode changes
  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      return; // Skip the animation on initial mount
    }
    if (!presentationMode) {
      const startPosition = camera.position.clone();
      const startQuaternion = camera.quaternion.clone();
      const startZoom = camera.zoom;

      let targetPosition = new THREE.Vector3();
      let targetQuaternion = new THREE.Quaternion();
      let targetZoom = camera.zoom;

      if (viewMode === 'isometric') {
        // Isometric view
        targetPosition.copy(defaultCameraPosition.current);
        targetQuaternion.copy(defaultCameraQuaternion.current);
        targetZoom = presentationStartZoom.current;
        setControlsTarget(new THREE.Vector3(0, 0, 0)); // Focus on the center
      } else if (viewMode === 'top-down') {
        // Top-down view
        targetPosition.set(0, 100, 0.001);
        const lookAtTarget = new THREE.Vector3(0, 0, 0);
        camera.up.set(0, 1, 0); // Ensure Y-axis is up
        camera.position.copy(targetPosition);
        camera.lookAt(lookAtTarget);
        targetQuaternion.copy(camera.quaternion);
        targetZoom = camera.zoom; // Keep the same zoom level
        camera.position.copy(startPosition);
        camera.quaternion.copy(startQuaternion);
        setControlsTarget(new THREE.Vector3(0, 0, 0)); // Focus on the center
      }

      animateCamera(
        startPosition,
        startQuaternion,
        startZoom,
        targetPosition,
        targetQuaternion,
        targetZoom,
        null, // targetLookAt
        () => {
          // Update controls' target after animation completes
          const lookAtPoint = new THREE.Vector3();
          camera.getWorldDirection(lookAtPoint);
          lookAtPoint.add(camera.position);
          setControlsTarget(lookAtPoint);
        }
      );
    }
  }, [viewMode, camera, presentationMode]);

  // Presentation step duration in milliseconds
  const presentationStepDuration = 4000; // Adjusted to 4 seconds

  useEffect(() => {
    if (presentationMode && currentWorkflow) {
      // Disable controls during presentation
      if (controlsRef.current) {
        controlsRef.current.enabled = false;
      }
      // Disable pointer events to prevent user interaction
      gl.domElement.style.pointerEvents = 'none';

      // Clear any existing timeouts to prevent overlapping
      if (presentationTimeoutId.current) {
        clearTimeout(presentationTimeoutId.current);
        presentationTimeoutId.current = null;
      }

      // Reset highlighted elements and active animations
      setHighlightedElements([]);
      setActiveAnimations([]);

      // Presentation logic that uses icons and connections
      const steps = currentWorkflow.steps;
      if (currentStepIndex >= steps.length) {
        // End of presentation
        setIsPlaying(false);
        return;
      }

      const processStep = (step) => {
        if (step.type === 'component') {
          const icon = icons.find((icon) => icon.id === step.targetId);
          if (icon) {
            const iconPosition = new THREE.Vector3(...icon.position);

            // Adjust the offset based on the camera's default orientation
            const offsetDirection = new THREE.Vector3(0, 30, 50);
            const targetPosition = iconPosition.clone().add(offsetDirection);

            const targetLookAt = iconPosition.clone();

            setHighlightedElements((prev) => [...prev, icon.id]);

            animateCamera(
              camera.position.clone(),
              camera.quaternion.clone(),
              camera.zoom,
              targetPosition,
              null,
              70, // Zoom in
              targetLookAt,
              () => {
                // Update controls' target after animation completes
                setControlsTarget(targetLookAt.clone());
              }
            );
          }
        } else if (step.type === 'connection') {
          const connection = connections.find(
            (conn) => conn.id === step.targetId
          );
          if (connection) {
            const startIcon = icons.find((icon) => icon.id === connection.startId);
            const endIcon = icons.find((icon) => icon.id === connection.endId);
            if (startIcon && endIcon) {
              const startVec = new THREE.Vector3(...startIcon.position);
              const endVec = new THREE.Vector3(...endIcon.position);
              const midPoint = startVec.clone().lerp(endVec, 0.5);

              // Calculate the camera offset direction
              const offsetDirection = new THREE.Vector3(0, 30, 50); // Adjust as needed
              const targetPosition = midPoint.clone().add(offsetDirection);

              const targetLookAt = midPoint.clone();

              // Highlight the connection and both components
              setHighlightedElements((prev) => [
                ...prev,
                connection.id,
                startIcon.id,
                endIcon.id,
              ]);

              animateCamera(
                camera.position.clone(),
                camera.quaternion.clone(),
                camera.zoom,
                targetPosition,
                null,
                70, // Zoom in
                targetLookAt,
                () => {
                  // Update controls' target after animation completes
                  setControlsTarget(targetLookAt.clone());
                }
              );
            }
          }
        } else if (step.type === 'animation') {
          const icon = icons.find((icon) => icon.id === step.targetId);
          if (icon) {
            // Add the animation step to active animations
            setActiveAnimations((prev) => [...prev, step]);

            // Position the camera to focus on the icon
            const iconPosition = new THREE.Vector3(...icon.position);

            // Adjust the offset based on the camera's default orientation
            const offsetDirection = new THREE.Vector3(0, 30, 50);
            const targetPosition = iconPosition.clone().add(offsetDirection);

            const targetLookAt = iconPosition.clone();

            animateCamera(
              camera.position.clone(),
              camera.quaternion.clone(),
              camera.zoom,
              targetPosition,
              null,
              70, // Zoom in
              targetLookAt,
              () => {
                // Update controls' target after animation completes
                setControlsTarget(targetLookAt.clone());
              }
            );
          }
        }
      };

      const executeSteps = (stepsArray) => {
        stepsArray.forEach((step) => {
          processStep(step);
          if (step.parallelSteps && step.parallelSteps.length > 0) {
            executeSteps(step.parallelSteps);
          }
        });
      };

      const currentStep = steps[currentStepIndex];
      if (!currentStep) return;

      // Execute the current step(s), including parallel steps
      executeSteps([currentStep]);

      // Advance to next step after the adjusted delay
      presentationTimeoutId.current = setTimeout(() => {
        if (isPlaying) {
          setCurrentStepIndex((prev) => prev + 1);
          // Remove completed animations
          setActiveAnimations([]);
          // Reset highlighted elements
          setHighlightedElements([]);
        }
      }, presentationStepDuration); // Use the new duration
    }
  }, [
    presentationMode,
    currentWorkflow,
    currentStepIndex,
    isPlaying,
    icons,
    connections,
    camera,
    gl.domElement,
    setCurrentStepIndex,
    setIsPlaying,
  ]);

  useEffect(() => {
    if (!presentationMode) {
      // Reset highlighted elements and active animations when not in presentation mode
      setHighlightedElements([]);
      setActiveAnimations([]);

      // Clear any existing timeouts
      if (presentationTimeoutId.current) {
        clearTimeout(presentationTimeoutId.current);
        presentationTimeoutId.current = null;
      }

      // Re-enable pointer events
      gl.domElement.style.pointerEvents = 'auto';

      // Animate camera back to default position smoothly
      animateCamera(
        camera.position.clone(),
        camera.quaternion.clone(),
        camera.zoom,
        defaultCameraPosition.current.clone(),
        defaultCameraQuaternion.current.clone(),
        presentationStartZoom.current,
        null, // targetLookAt
        () => {
          // Re-enable controls after animation completes
          if (controlsRef.current) {
            controlsRef.current.enabled = true;
          }
          // Update controls' target to default
          setControlsTarget(new THREE.Vector3(0, 0, 0));
        }
      );
    }
  }, [presentationMode, camera, gl.domElement]);

  // Function to animate camera movement
  const animateCamera = (
    startPos,
    startQuat,
    startZoom,
    targetPos,
    targetQuat = null,
    targetZoom = null,
    targetLookAt = null,
    onComplete = null
  ) => {
    presentationStartPosition.current.copy(startPos);
    presentationStartQuaternion.current.copy(startQuat);
    presentationStartZoom.current = startZoom;

    // Ensure the camera's up vector is set
    camera.up.set(0, 1, 0);

    if (targetLookAt) {
      // Use a temporary camera to calculate the target quaternion
      const tempCam = new THREE.PerspectiveCamera();
      tempCam.up.set(0, 1, 0); // Ensure Y-axis is up
      tempCam.position.copy(targetPos);
      tempCam.lookAt(targetLookAt);
      presentationTargetPosition.current.copy(targetPos);
      presentationTargetQuaternion.current.copy(tempCam.quaternion);
    } else if (targetQuat) {
      presentationTargetPosition.current.copy(targetPos);
      presentationTargetQuaternion.current.copy(targetQuat);
    } else {
      // If neither targetQuat nor targetLookAt is provided, default to current camera orientation
      presentationTargetPosition.current.copy(targetPos);
      presentationTargetQuaternion.current.copy(camera.quaternion);
    }

    presentationTargetZoom.current = targetZoom !== null ? targetZoom : camera.zoom;

    presentationAnimationProgress.current = 0;
    presentationAnimating.current = true;

    // Store onComplete callback
    presentationOnComplete.current = onComplete;
  };

  // Frame update for animations
  useFrame((state, delta) => {
    // Handle presentation camera animation
    if (presentationAnimating.current) {
      presentationAnimationProgress.current += delta;
      const duration = 2; // Duration of the animation in seconds
      const t = Math.min(presentationAnimationProgress.current / duration, 1);

      // Apply an easing function for smoother animations
      const easeInOutQuad = (t) =>
        t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
      const easedT = easeInOutQuad(t);

      camera.position.lerpVectors(
        presentationStartPosition.current,
        presentationTargetPosition.current,
        easedT
      );
      camera.quaternion.slerpQuaternions(
        presentationStartQuaternion.current,
        presentationTargetQuaternion.current,
        easedT
      );
      camera.zoom =
        presentationStartZoom.current +
        (presentationTargetZoom.current - presentationStartZoom.current) * easedT;
      camera.updateProjectionMatrix();

      if (t >= 1) {
        presentationAnimating.current = false;

        // Call onComplete callback if provided
        if (presentationOnComplete.current) {
          presentationOnComplete.current();
          presentationOnComplete.current = null;
        }
      }
    }

    // Update controls only if enabled
    if (controlsRef.current && controlsRef.current.enabled) {
      controlsRef.current.update();
    }
  });

  // Cleanup timeout when component unmounts or step changes
  useEffect(() => {
    return () => {
      if (presentationTimeoutId.current) {
        clearTimeout(presentationTimeoutId.current);
        presentationTimeoutId.current = null;
      }
    };
  }, [currentStepIndex]);

  const isMovingIcon = movingIconId !== null;

  // Animation components mapping
  const animationComponents = {
    encryption: EncryptionAnimation,
    authentication: AuthenticationAnimation,
    // Add more animations here
  };

  // Icon heights for positioning animations
  const iconHeights = {
    server: 2,
    client: 2,
    default: 2,
  };

  return (
    <>
      <ambientLight intensity={0.5} />
      <directionalLight
        position={[10, 20, 10]}
        intensity={1}
        castShadow
        shadow-mapSize-width={1024}
        shadow-mapSize-height={1024}
      />
      <GridComponent
        icons={icons}
        setIcons={setIcons}
        onIconClick={onIconClick}
        connections={connections}
        setConnections={setConnections}
        selectedIcon={selectedIcon}
        menuOpenIconId={menuOpenIconId}
        onRename={onRename}
        onStartMove={onStartMove}
        onStartConnect={onStartConnect}
        onStartChange={onStartChange} // Pass this prop
        movingIconId={movingIconId}
        onMoveEnd={onMoveEnd}
        setIconPosition={setIconPosition}
        onConnectionClick={onConnectionClick}
        menuOpenConnectionId={menuOpenConnectionId}
        onDeleteConnection={onDeleteConnection}
        onEditConnection={onEditConnection}
        isCurved={isCurved}
        onMenuToggle={onMenuToggle}
        highlightedElements={highlightedElements}
        viewMode={viewMode}
        showConnectionLabels={showConnectionLabels}
      />
      {/* Show CreatingIcon when adding a new icon */}
      {creatingIcon && (
        <>
          <CreatingIcon
            creatingIcon={creatingIcon}
            setCreatingIcon={setCreatingIcon}
            setIcons={setIcons}
          />
          {/* Show GridOverlay when creating a new icon */}
          <GridOverlay show={true} />
        </>
      )}
      {/* Show GridOverlay when moving an icon */}
      {!creatingIcon && <GridOverlay show={isMovingIcon} />}
      {/* Render Active Animations */}
      {activeAnimations.map((animationStep) => {
        const icon = icons.find((icon) => icon.id === animationStep.targetId);
        if (!icon) return null;

        const AnimationComponent = animationComponents[animationStep.animation];
        if (!AnimationComponent) return null;

        // Get the height of the component
        const iconHeight = iconHeights[icon.iconType] || iconHeights.default;

        // Position the animation above the component
        const animationPosition = [
          icon.position[0],
          icon.position[1] + iconHeight + 0.5, // Increased offset
          icon.position[2],
        ];

        return (
          <AnimationComponent
            key={animationStep.id}
            position={animationPosition}
          />
        );
      })}
      <MapControls
        ref={controlsRef}
        makeDefault
        enableRotate={false}
        enableZoom={true}
        enablePan={!isMovingIcon && !presentationMode}
        screenSpacePanning={viewMode === 'top-down'}
        minZoom={5}
        maxZoom={100}
        mouseButtons={{
          LEFT: isMovingIcon || presentationMode ? null : THREE.MOUSE.PAN,
          MIDDLE: THREE.MOUSE.DOLLY,
          RIGHT: null,
        }}
        target={controlsTarget}
      />
    </>
  );
};

export default SceneContent;
