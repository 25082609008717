import React from "react";
import S3BucketMesh from "./S3BucketMesh";
import DatabaseMesh from "./DatabaseMesh";
import VaultMesh from "./VaultMesh";
import FileMesh from "./FileMesh";
import SQSMesh from "./SQSMesh";
import DefaultIconMesh from "./DefaultIconMesh";
import ReactMesh from "./ReactMesh";
import ComputerMesh from "./ComputerMesh";
import EC2Mesh from "./EC2Mesh";
import NginxMesh from './NginxMesh';
import WAFMesh from './WAFMesh';
import CloudFrontMesh from './CloudFrontMesh'; // Import the new CloudFrontMesh component

const IconMesh = ({ icon, isHighlighted }) => {
  // Define an array of all database types handled by DatabaseMesh
  const databaseTypes = ['DynamoDB', 'MongoDB', 'RDS', 'Redshift', 'Aurora', 'SQL-Server'];

  if (icon.iconType === "S3") {
    return <S3BucketMesh isHighlighted={isHighlighted} />;
  } else if (databaseTypes.includes(icon.iconType)) {
    return (
      <DatabaseMesh
        iconType={icon.iconType}
        isHighlighted={isHighlighted}
      />
    );
  } else if (icon.iconType === "Vault") {
    return <VaultMesh isHighlighted={isHighlighted} />;
  } else if (icon.iconType === "SFTP") {
    return <FileMesh isHighlighted={isHighlighted} />;
  } else if (icon.iconType === "SQS") {
    return <SQSMesh isHighlighted={isHighlighted} />;
  } else if (icon.iconType === "React") {
    return <ReactMesh isHighlighted={isHighlighted} />;
  } else if (icon.iconType === "Computer") {
    return <ComputerMesh isHighlighted={isHighlighted} />;
  } else if (icon.iconType === "EC2") {
    return <EC2Mesh isHighlighted={isHighlighted} />;
  } else if (icon.iconType === "Nginx") {
    return <NginxMesh isHighlighted={isHighlighted} />;
  } else if (icon.iconType === "WAF") {
    return <WAFMesh isHighlighted={isHighlighted} />;
  } else if (icon.iconType === "CloudFront") { // Add condition for CloudFront
    return <CloudFrontMesh isHighlighted={isHighlighted} />;
  } else {
    return <DefaultIconMesh icon={icon} isHighlighted={isHighlighted} />;
  }
};

export default IconMesh;
