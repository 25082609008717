// frontend/src/components/UI/ExportButton.js

import React from 'react';

const ExportButton = () => {
  const handleExport = () => {
    const fileName = prompt('Enter a name for the PNG file (without extension):', 'diagram');
    if (!fileName) {
      return; // User cancelled
    }

    const canvas = document.querySelector('canvas');
    if (canvas) {
      // Ensure the latest frame is rendered
      requestAnimationFrame(() => {
        const link = document.createElement('a');
        link.href = canvas.toDataURL('image/png');
        link.download = `${fileName}.png`;
        link.click();
      });
    } else {
      alert('Canvas not found!');
    }
  };

  return (
    <button onClick={handleExport} className="export-button">
      Export Diagram PNG
    </button>
  );
};

export default ExportButton;
