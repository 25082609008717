// src/components/Meshes/ReactMesh.js

import React, { useRef, useMemo } from 'react';
import { useFrame, useLoader } from '@react-three/fiber';
import * as THREE from 'three';

const ReactMesh = ({ isHighlighted }) => {
  const groupRef = useRef();
  const atomRef = useRef();

  // Color configurations
  const colors = useMemo(() => ({
    main: isHighlighted ? '#61DAFB' : '#00D8FF', // React blue
    ring: '#61DAFB', // Light blue for React
  }), [isHighlighted]);

  // Load React texture
  const texture = useLoader(THREE.TextureLoader, `${process.env.PUBLIC_URL}/icons/React.png`);

  // Create materials
  const materials = useMemo(() => ({
    atom: new THREE.ShaderMaterial({
      uniforms: {
        color: { value: new THREE.Color(colors.main) },
        time: { value: 0 },
      },
      vertexShader: `
        varying vec3 vNormal;
        void main() {
          vNormal = normalize(normalMatrix * normal);
          gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
        }
      `,
      fragmentShader: `
        uniform vec3 color;
        uniform float time;
        varying vec3 vNormal;
        void main() {
          float intensity = pow(0.7 - dot(vNormal, vec3(0, 0, 1.0)), 2.0);
          intensity += 0.3 * sin(time * 2.0) * sin(time * 2.3);
          vec3 glow = color * intensity * 1.5;
          gl_FragColor = vec4(glow, 1.0);
        }
      `,
    }),
    ring: new THREE.MeshBasicMaterial({
      color: colors.ring,
      side: THREE.DoubleSide,
      transparent: true,
    }),
  }), [colors]);

  // Animation
  useFrame((state, delta) => {
    if (groupRef.current) {
      const pulseFactor = Math.sin(state.clock.elapsedTime * 2) * 0.05 + 1;
      groupRef.current.scale.set(pulseFactor, pulseFactor, pulseFactor);
    }
    materials.atom.uniforms.time.value = state.clock.elapsedTime;
  });

  return (
    <group>
      <group ref={groupRef}>
        {/* Central atom */}
        <mesh ref={atomRef}>
          <sphereGeometry args={[0.5, 32, 32]} />
          <primitive object={materials.atom} />
        </mesh>

        {/* Outer glow for atom */}
        <mesh>
          <sphereGeometry args={[0.55, 32, 32]} />
          <meshBasicMaterial color={colors.glow} transparent opacity={0.3} />
        </mesh>

        {/* Rings without glow effect */}
        <group rotation={[Math.PI / 2, 0, 0]}>
          <mesh>
            <torusGeometry args={[1.2, 0.08, 32, 100]} />
            <primitive object={materials.ring} />
          </mesh>
        </group>
        <group rotation={[0, Math.PI / 2, 0]}>
          <mesh>
            <torusGeometry args={[1.2, 0.08, 32, 100]} />
            <primitive object={materials.ring} />
          </mesh>
        </group>
        <group>
          <mesh>
            <torusGeometry args={[1.2, 0.08, 32, 100]} />
            <primitive object={materials.ring} />
          </mesh>
        </group>
      </group>

      {/* Fixed 2D React Logo on the grid */}
      <mesh position={[2.5, 0.01, 1.5]} rotation={[-Math.PI / 2, 0, 0]}>
        <planeGeometry args={[0.8, 0.8]} />
        <meshBasicMaterial map={texture} transparent side={THREE.DoubleSide} />
      </mesh>
    </group>
  );
};

export default ReactMesh;
