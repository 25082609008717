// frontend/src/components/ConnectionEditModal.js

import React, { useState } from 'react';
import Modal from 'react-modal';

// Set the app element for accessibility
Modal.setAppElement('#root');

const ConnectionEditModal = ({ connection, onUpdateConnection, onClose }) => {
  const [color, setColor] = useState(connection.color || 'black');
  const [direction, setDirection] = useState(connection.direction);
  const [name, setName] = useState(connection.name || '');

  const handleSubmit = (e) => {
    e.preventDefault();
    onUpdateConnection({ color, direction, name });
  };

  return (
    <Modal
      isOpen={true}
      onRequestClose={onClose}
      contentLabel="Edit Connection"
      className="modal"
      overlayClassName="modal-overlay"
    >
      <h2>Edit Connection</h2>
      <form onSubmit={handleSubmit}>
        <label>
          Name/Type:
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            maxLength={20}
          />
        </label>
        <br />
        <label>
          Color:
          <input
            type="color"
            value={color}
            onChange={(e) => setColor(e.target.value)}
          />
        </label>
        <br />
        <label>
          Direction:
          <select
            value={direction}
            onChange={(e) => setDirection(e.target.value)}
          >
            <option value="start-to-end">Start to End</option>
            <option value="end-to-start">End to Start</option>
          </select>
        </label>
        <br />
        <button type="submit">Update Connection</button>
        <button type="button" onClick={onClose} style={{ marginLeft: '10px' }}>
          Cancel
        </button>
      </form>
    </Modal>
  );
};

export default ConnectionEditModal;
