// src/components/UI/ConnectionLine.js

import React, { useState, useMemo, useRef, useEffect } from 'react';
import { Line, Text, Html } from '@react-three/drei';
import { useFrame } from '@react-three/fiber';
import * as THREE from 'three';

// Custom hook to get the previous value
function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}

const ConnectionLine = ({
  connection,
  icons,
  onClick,
  menuOpenConnectionId,
  onDeleteConnection,
  onEditConnection,
  isCurved,
  isHighlighted,
  showLabel
}) => {
  const [hovered, setHovered] = useState(false);
  const textRef = useRef();

  // Initialize animationProgress to 1
  const [animationProgress, setAnimationProgress] = useState(1);
  const animationDuration = 4; // Duration of the animation in seconds

  // Destructure connection properties with default values
  const {
    startId,
    endId,
    direction = 'start-to-end',
    color = 'black',
    id,
    name = '',
  } = connection;

  // Find start and end icons
  const startIcon = icons.find((icon) => icon.id === startId);
  const endIcon = icons.find((icon) => icon.id === endId);

  const isMenuOpen = menuOpenConnectionId === id;

  // Use the custom hook to get previous values
  const prevIsHighlighted = usePrevious(isHighlighted);

  // Update animation progress when highlighted
  useFrame((state, delta) => {
    if (textRef.current) {
      // Remove the following line to prevent text from facing the camera
      // textRef.current.quaternion.copy(state.camera.quaternion);
    }

    if (isHighlighted) {
      setAnimationProgress((prevProgress) => {
        let newProgress = prevProgress + delta / animationDuration;
        if (newProgress > 1) {
          newProgress = 0; // Reset to 0 to loop the animation
        }
        return newProgress;
      });
    } else {
      // Ensure line is fully drawn when not highlighted
      if (animationProgress !== 1) {
        setAnimationProgress(1);
      }
    }
  });

  // Calculate the curve and adjusted start/end points
  const { curve, adjustedStart, adjustedEnd } = useMemo(() => {
    if (!startIcon || !endIcon) {
      return { curve: null, adjustedStart: null, adjustedEnd: null };
    }

    const startVec = new THREE.Vector3(...startIcon.position);
    const endVec = new THREE.Vector3(...endIcon.position);

    // Adjust positions to bring arrowhead to touch the component
    const iconSize = 1.5; // Half of the icon size (since icons are 3 units wide)
    const directionVec = new THREE.Vector3()
      .subVectors(endVec, startVec)
      .normalize();

    let adjustedStart, adjustedEnd;

    if (direction === 'start-to-end') {
      adjustedStart = startVec
        .clone()
        .add(directionVec.clone().multiplyScalar(iconSize));
      adjustedEnd = endVec
        .clone()
        .sub(directionVec.clone().multiplyScalar(iconSize));
    } else {
      // 'end-to-start'
      adjustedStart = endVec
        .clone()
        .add(directionVec.clone().multiplyScalar(iconSize));
      adjustedEnd = startVec
        .clone()
        .sub(directionVec.clone().multiplyScalar(iconSize));
    }

    let curveInstance;
    if (isCurved) {
      // Create smooth curve using perpendicular offset
      const midpoint = adjustedStart.clone().lerp(adjustedEnd, 0.5);

      // Calculate a perpendicular vector in the X-Z plane
      const horizontalDir = new THREE.Vector3()
        .subVectors(adjustedEnd, adjustedStart)
        .normalize();
      const perpendicular = new THREE.Vector3(
        -horizontalDir.z,
        0,
        horizontalDir.x
      ); // Perpendicular in X-Z plane
      const offsetScalar = 2; // Adjust for curvature intensity
      const offset = perpendicular.multiplyScalar(offsetScalar);

      const curvedMidpoint = midpoint.clone().add(offset);

      curveInstance = new THREE.CatmullRomCurve3([
        adjustedStart,
        curvedMidpoint,
        adjustedEnd,
      ]);
    } else {
      // Straight line
      curveInstance = new THREE.LineCurve3(adjustedStart, adjustedEnd);
    }

    return { curve: curveInstance, adjustedStart, adjustedEnd };
  }, [startIcon, endIcon, direction, isCurved]);

  // Conditional rendering after Hooks
  if (!curve || !adjustedEnd) {
    // Log warning if needed
    console.warn(
      `Connection with ID ${id} references non-existent icons: startId=${startId}, endId=${endId}`
    );
    return null;
  }

  // Generate points along the curve
  const totalPoints = 100;
  const points = curve.getPoints(totalPoints);

  // Determine the number of points to display based on animation progress
  const animatedPointCount = Math.floor(totalPoints * animationProgress);

  // Only display the line if there are enough points
  const displayedPoints =
    animatedPointCount >= 2 ? points.slice(0, animatedPointCount) : null;

  // Calculate arrowhead position and orientation for 2D arrowhead
  const arrowLength = 0.5; // Length of each side of the arrowhead
  const arrowAngle = Math.PI / 6; // 30 degrees for the arrowhead

  // Determine the direction vector
  const directionVec = new THREE.Vector3()
    .subVectors(
      new THREE.Vector3(...endIcon.position),
      new THREE.Vector3(...startIcon.position)
    )
    .normalize();

  // If direction is 'end-to-start', invert the direction vector
  const finalDirection =
    direction === 'start-to-end' ? directionVec : directionVec.clone().negate();

  // Calculate the angle of the direction vector
  const angle = Math.atan2(finalDirection.z, finalDirection.x);

  // Determine current end point for the arrowhead
  const currentEndPoint =
    displayedPoints && displayedPoints[displayedPoints.length - 1]
      ? displayedPoints[displayedPoints.length - 1]
      : adjustedStart;

  // Calculate the two points for the arrowhead lines
  const leftArrow = new THREE.Vector3(
    currentEndPoint.x - arrowLength * Math.cos(angle - arrowAngle),
    currentEndPoint.y,
    currentEndPoint.z - arrowLength * Math.sin(angle - arrowAngle)
  );

  const rightArrow = new THREE.Vector3(
    currentEndPoint.x - arrowLength * Math.cos(angle + arrowAngle),
    currentEndPoint.y,
    currentEndPoint.z - arrowLength * Math.sin(angle + arrowAngle)
  );

  const handleClick = (event) => {
    event.stopPropagation();
    onClick(id);
  };

  const handlePointerOver = () => {
    setHovered(true);
  };

  const handlePointerOut = () => {
    setHovered(false);
  };

  const lineColor = isHighlighted ? 'yellow' : hovered ? 'orange' : color;

  return (
    <group
      onClick={handleClick}
      onPointerOver={handlePointerOver}
      onPointerOut={handlePointerOut}
      renderOrder={1} // Ensure the group is rendered early
      frustumCulled={false} // Prevent culling of the entire group
    >
      {/* Connection Line */}
      {displayedPoints && (
        <Line
          points={displayedPoints}
          color={lineColor}
          lineWidth={2}
          dashed={false}
          frustumCulled={false} // Prevent culling
          renderOrder={1} // Render first
        />
      )}
      {/* Arrowheads */}
      {animationProgress > 0 && (
        <>
          {/* Arrowhead Left Line */}
          <Line
            points={[currentEndPoint.toArray(), leftArrow.toArray()]}
            color={lineColor}
            lineWidth={2}
            frustumCulled={false} // Prevent culling
            renderOrder={2} // Render after the main line
          />
          {/* Arrowhead Right Line */}
          <Line
            points={[currentEndPoint.toArray(), rightArrow.toArray()]}
            color={lineColor}
            lineWidth={2}
            frustumCulled={false} // Prevent culling
            renderOrder={2} // Render after the main line
          />
        </>
      )}
      {/* Connection Label */}
      {name && showLabel && (
        <group
          position={[
            curve.getPointAt(0.5).x,
            0.01, // Changed from curve.getPointAt(0.5).y + 0.7 to be just above the grid
            curve.getPointAt(0.5).z,
          ]}
          renderOrder={2}
          frustumCulled={false}
          rotation={[-Math.PI / 2, 0, 0]}
        >
          <Text
            ref={textRef}
            position={[0, 0, 0.02]}
            fontSize={0.5}
            color="black"
            anchorX="center"
            anchorY="middle"
            renderOrder={3}
            outlineWidth={0.02}
            outlineColor="gray"
            fontFace="Arial"
          >
            {name}
          </Text>
        </group>
      )}
      {/* Connection Menu */}
      {isMenuOpen && (
        <Html position={curve.getPointAt(0.5).toArray()} center>
          <div className="connection-menu">
            <button
              className="connection-button"
              onClick={(e) => {
                e.stopPropagation();
                onEditConnection(id);
              }}
            >
              Edit
            </button>
            <button
              className="connection-button"
              onClick={(e) => {
                e.stopPropagation();
                onDeleteConnection(id);
              }}
            >
              Delete
            </button>
          </div>
        </Html>
      )}
    </group>
  );
};

export default ConnectionLine;
