// frontend/src/components/DefaultIconMesh.js

import React, { useMemo } from 'react';
import { useLoader } from '@react-three/fiber';
import * as THREE from 'three';

const DefaultIconMesh = ({ icon, isHighlighted }) => {
  // Load the icon texture using useLoader
  const iconTexture = useLoader(
    THREE.TextureLoader,
    `${process.env.PUBLIC_URL}/icons/${icon.iconType}.png`
  );

  // Memoize materials to prevent re-creation
  const materials = useMemo(
    () => [
      new THREE.MeshStandardMaterial({
        color: isHighlighted ? '#FFD700' : '#ADD8E6',
      }),
      new THREE.MeshStandardMaterial({
        color: isHighlighted ? '#FFD700' : '#ADD8E6',
      }),
      new THREE.MeshStandardMaterial({ map: iconTexture }),
      new THREE.MeshStandardMaterial({
        color: isHighlighted ? '#FFD700' : '#ADD8E6',
      }),
      new THREE.MeshStandardMaterial({
        color: isHighlighted ? '#FFD700' : '#ADD8E6',
      }),
      new THREE.MeshStandardMaterial({
        color: isHighlighted ? '#FFD700' : '#ADD8E6',
      }),
    ],
    [iconTexture, isHighlighted]
  );

  return (
    <>
      <boxGeometry args={[3, 1, 3]} />
      {materials.map((material, index) => (
        <primitive
          attach={`material-${index}`}
          key={index}
          object={material}
        />
      ))}
    </>
  );
};

export default DefaultIconMesh;
