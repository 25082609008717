// src/App.js

import React, { useState, useRef } from 'react';
import './CloudDiagrams.css'; // Create and configure as needed
import { Canvas } from '@react-three/fiber';
import { Github } from 'lucide-react';
import IconPalette from './UI/IconPalette';
import ExportButton from './UI/ExportButton';
import ConnectModal from './Modals/ConnectModal';
import ConnectionEditModal from './Modals/ConnectionEditModal';
import WorkflowPanel from './Workflows/WorkflowPanel';
import PresentationMode from './Workflows/PresentationMode';
import { WorkflowModals } from './Modals/WorkflowModals';
import ChangeIconModal from './Modals/ChangeIconModal';
import SceneContent from '../SceneContent';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

const CloudDiagrams = () => {
  const [icons, setIcons] = useState([]);
  const [connections, setConnections] = useState([]);
  const [selectedIcon, setSelectedIcon] = useState(null);
  const [menuOpenIconId, setMenuOpenIconId] = useState(null);
  const [movingIconId, setMovingIconId] = useState(null);
  const [connectModalOpen, setConnectModalOpen] = useState(false);
  const [connectFromIcon, setConnectFromIcon] = useState(null);
  const [connectionModalOpen, setConnectionModalOpen] = useState(false);
  const [selectedConnectionIndex, setSelectedConnectionIndex] = useState(null);
  const [creatingIcon, setCreatingIcon] = useState(null);
  const [isCurved, setIsCurved] = useState(false);
  const [viewMode, setViewMode] = useState('isometric');
  const [menuOpenConnectionId, setMenuOpenConnectionId] = useState(null);

  // Workflow-related states
  const [workflows, setWorkflows] = useState([]);
  const [currentWorkflow, setCurrentWorkflow] = useState(null);
  const [presentationMode, setPresentationMode] = useState(false);
  const [workflowModals, setWorkflowModals] = useState({
    create: false,
    edit: false,
  });
  const [selectedWorkflowId, setSelectedWorkflowId] = useState(null);

  // Presentation-related states
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);

  // State variable for label visibility
  const [showConnectionLabels, setShowConnectionLabels] = useState(true);

  // Reference to the hidden file input
  const fileInputRef = useRef(null);

  // Change icon modal state
  const [iconToChange, setIconToChange] = useState(null);
  const [changeModalOpen, setChangeModalOpen] = useState(false);

  // Add this near your other state declarations
  const [showWorkflows, setShowWorkflows] = useState(false);

  // Handle exporting diagram data as JSON
  const handleExportDiagram = () => {
    const fileName = prompt(
      'Enter a name for the JSON file (without extension):',
      'diagram'
    );
    if (!fileName) {
      return; // User cancelled
    }
    const data = {
      icons,
      connections,
      workflows, // Include workflows in the exported data
    };

    const jsonData = JSON.stringify(data, null, 2);

    const blob = new Blob([jsonData], { type: 'application/json' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = `${fileName}.json`;
    link.click();

    // Revoke the object URL after download
    URL.revokeObjectURL(link.href);
  };

  // Handle loading diagram from JSON file
  const handleLoadDiagram = (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = (e) => {
      try {
        const data = JSON.parse(e.target.result);
        const {
          icons: loadedIcons,
          connections: loadedConnections,
          workflows: loadedWorkflows,
        } = data;

        // Validate and assign default properties to connections
        const connectionsWithId = (loadedConnections || []).map((conn, index) => {
          // Assign a unique ID if missing
          const id = conn.id || Date.now() + Math.random();

          // Assign default direction if missing
          const direction = conn.direction || 'start-to-end';

          // Assign default color if missing
          const color = conn.color || 'black';

          // Assign default name if missing
          const name = conn.name || '';

          // Validate startId and endId
          if (!loadedIcons.find((icon) => icon.id === conn.startId)) {
            console.warn(
              `Imported connection at index ${index} has invalid startId: ${conn.startId}`
            );
          }
          if (!loadedIcons.find((icon) => icon.id === conn.endId)) {
            console.warn(
              `Imported connection at index ${index} has invalid endId: ${conn.endId}`
            );
          }

          return {
            ...conn,
            id,
            direction,
            color,
            name,
          };
        });

        setIcons(loadedIcons || []);
        setConnections(connectionsWithId);
        setWorkflows(loadedWorkflows || []); // Load workflows
      } catch (error) {
        console.error('Error parsing JSON:', error);
        alert('Failed to load diagram: Invalid JSON file.');
      }
    };
    reader.readAsText(file);

    // Reset the input value to allow loading the same file again if needed
    event.target.value = '';
  };

  // Handle starting to create a new icon
  const handleStartCreate = (name) => {
    const limitedName = name.length > 20 ? name.substring(0, 20) + '...' : name;
    const icon = {
      id: Date.now(),
      name: limitedName,
      iconType: name,
      position: [0, 0.5, 0],
    };
    setCreatingIcon(icon);
  };

  // Handle moving an icon
  const setIconPosition = (id, newPosition) => {
    setIcons((prevIcons) =>
      prevIcons.map((icon) =>
        icon.id === id ? { ...icon, position: newPosition } : icon
      )
    );
  };

  const handleMoveEnd = () => {
    setMovingIconId(null);
  };

  // Handle icon selection and menu toggle
  const handleIconClick = (icon) => {
    setSelectedIcon(icon);
  };

  const handleMenuToggle = (icon) => {
    setMenuOpenIconId((prevId) => (prevId === icon.id ? null : icon.id));
    setMenuOpenConnectionId(null); // Close connection menus when opening an icon menu
  };

  // Handle deletion of components
  const handleDelete = (id) => {
    setIcons((prevIcons) => prevIcons.filter((icon) => icon.id !== id));
    setConnections((prevConnections) =>
      prevConnections.filter((conn) => conn.startId !== id && conn.endId !== id)
    );
    if (selectedIcon && selectedIcon.id === id) {
      setSelectedIcon(null);
    }
    if (menuOpenIconId === id) {
      setMenuOpenIconId(null);
    }
    if (movingIconId === id) {
      setMovingIconId(null);
    }
    // Remove steps involving the deleted icon from workflows
    setWorkflows((prevWorkflows) =>
      prevWorkflows.map((workflow) => ({
        ...workflow,
        steps: workflow.steps.filter(
          (step) => !(step.type === 'component' && step.targetId === id)
        ),
      }))
    );
  };

  // Handle renaming of components
  const handleRename = (icon) => {
    const input = prompt('Enter new name:', icon.name);
    if (input && input.trim() !== '') {
      const limitedName =
        input.length > 20 ? input.substring(0, 20) + '...' : input;
      setIcons((prevIcons) =>
        prevIcons.map((i) => (i.id === icon.id ? { ...i, name: limitedName } : i))
      );
    }
  };

  // Handle starting to move an icon
  const handleStartMove = (icon) => {
    setMovingIconId(icon.id);
    setMenuOpenIconId(null);
  };

  // Handle starting to connect from an icon
  const handleStartConnect = (icon) => {
    setConnectFromIcon(icon);
    setConnectModalOpen(true);
    setMenuOpenIconId(null);
  };

  // Handle starting to change an icon
  const handleStartChange = (icon) => {
    setIconToChange(icon);
    setChangeModalOpen(true);
    setMenuOpenIconId(null);
  };

  // Handle changing an icon
  const handleChangeIcon = (newIconType) => {
    const updatedIcon = {
      ...iconToChange,
      iconType: newIconType,
      name: newIconType, // Optionally update the name
    };
    setIcons((prevIcons) =>
      prevIcons.map((icon) =>
        icon.id === iconToChange.id ? updatedIcon : icon
      )
    );
    // Update workflows that have steps involving this icon
    setWorkflows((prevWorkflows) =>
      prevWorkflows.map((workflow) => ({
        ...workflow,
        steps: updateStepsWithNewIcon(workflow.steps, iconToChange.id, updatedIcon.name),
      }))
    );
    setChangeModalOpen(false);
    setIconToChange(null);
  };

  // Helper function to update steps in workflows
  const updateStepsWithNewIcon = (steps, iconId, newIconName) => {
    if (!Array.isArray(steps)) return steps;
    return steps.map((step) => {
      let updatedStep = { ...step };
      if (step.targetId === iconId && step.type === 'animation') {
        // Update the step's name to reflect the new component name
        updatedStep.name = `${step.animation} on ${newIconName}`;
      } else if (step.parallelSteps && step.parallelSteps.length > 0) {
        // Recursively update parallel steps
        updatedStep.parallelSteps = updateStepsWithNewIcon(
          step.parallelSteps,
          iconId,
          newIconName
        );
      }
      return updatedStep;
    });
  };

  // Handle creating a connection
  const handleCreateConnection = (
    targetIconId,
    direction,
    connectionName
  ) => {
    const targetIcon = icons.find((icon) => icon.id === targetIconId);
    if (!targetIcon) {
      alert('Invalid component selected.');
      return;
    }

    // Prevent self-connection and duplicate connections
    const exists = connections.some(
      (conn) =>
        (conn.startId === connectFromIcon.id &&
          conn.endId === targetIcon.id &&
          conn.direction === direction) ||
        (conn.startId === targetIcon.id &&
          conn.endId === connectFromIcon.id &&
          conn.direction === direction)
    );
    if (exists) {
      alert(
        'Connection already exists between these components in this direction.'
      );
      return;
    }

    // Add the connection with the provided name/type
    setConnections((prevConnections) => [
      ...prevConnections,
      {
        id: Date.now(), // Add unique ID for the connection
        startId:
          direction === 'start-to-end' ? connectFromIcon.id : targetIcon.id,
        endId:
          direction === 'start-to-end' ? targetIcon.id : connectFromIcon.id,
        direction,
        color: 'black', // Default color
        name: connectionName || 'Connection', // Add name or type
      },
    ]);

    setConnectModalOpen(false);
    setConnectFromIcon(null);
  };

  // Handle connection click
  const handleConnectionClick = (connectionId) => {
    setMenuOpenConnectionId((prevId) =>
      prevId === connectionId ? null : connectionId
    );
    setMenuOpenIconId(null); // Close any open icon menus
  };

  // Handle deleting a connection
  const handleDeleteConnection = (connectionId) => {
    const deletedConnection = connections.find(
      (conn) => conn.id === connectionId
    );
    setConnections((prevConnections) =>
      prevConnections.filter((conn) => conn.id !== connectionId)
    );
    setMenuOpenConnectionId(null);

    // Remove steps involving the deleted connection from workflows
    setWorkflows((prevWorkflows) =>
      prevWorkflows.map((workflow) => ({
        ...workflow,
        steps: workflow.steps.filter(
          (step) =>
            !(
              step.targetId === deletedConnection.id &&
              step.type === 'connection'
            )
        ),
      }))
    );
  };

  // Handle editing a connection
  const handleEditConnection = (connectionId) => {
    const index = connections.findIndex((conn) => conn.id === connectionId);
    setSelectedConnectionIndex(index);
    setConnectionModalOpen(true);
    setMenuOpenConnectionId(null);
  };

  // Toggle function for line type
  const toggleLineType = () => {
    setIsCurved((prev) => !prev);
  };

  // Handle Load Diagram button click
  const handleLoadButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  // Workflow management handlers
  const handleCreateWorkflow = (workflow) => {
    setWorkflows((prevWorkflows) => [...prevWorkflows, workflow]);
    setWorkflowModals({ ...workflowModals, create: false });
  };

  const handleEditWorkflow = (updatedWorkflow) => {
    setWorkflows((prevWorkflows) =>
      prevWorkflows.map((wf) =>
        wf.id === updatedWorkflow.id ? updatedWorkflow : wf
      )
    );
    setWorkflowModals({ ...workflowModals, edit: false });
    setSelectedWorkflowId(null);
  };

  const handleDeleteWorkflow = (id) => {
    setWorkflows((prevWorkflows) => prevWorkflows.filter((wf) => wf.id !== id));
  };

  const handleStartPresentation = (workflowId) => {
    const workflow = workflows.find((wf) => wf.id === workflowId);
    if (workflow) {
      setCurrentWorkflow(workflow);
      setCurrentStepIndex(0);
      setIsPlaying(true);
      setPresentationMode(true);
      // No need to modify showConnectionLabels here
    }
  };

  const handleExitPresentation = () => {
    setPresentationMode(false);
    setCurrentWorkflow(null);
    setIsPlaying(false);
    setCurrentStepIndex(0);
  };

  // Handler for toggling connection labels
  const handleToggleLabels = () => {
    setShowConnectionLabels((prev) => !prev);
  };

  // Handle URL parameters for auto-loading files
  React.useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const fileToLoad = queryParams.get('load');
    
    if (fileToLoad) {
        fetch(`/json/${fileToLoad}`)
            .then(response => response.json())
            .then(data => {
                const {
                    icons: loadedIcons,
                    connections: loadedConnections,
                    workflows: loadedWorkflows,
                } = data;

                // Process connections with default properties
                const connectionsWithId = (loadedConnections || []).map((conn, index) => ({
                    ...conn,
                    id: conn.id || Date.now() + Math.random(),
                    direction: conn.direction || 'start-to-end',
                    color: conn.color || 'black',
                    name: conn.name || '',
                }));

                setIcons(loadedIcons || []);
                setConnections(connectionsWithId);
                setWorkflows(loadedWorkflows || []);
            })
            .catch(error => {
                console.error('Error loading diagram:', error);
                alert('Failed to load diagram file.');
            });
    }
  }, []); // Empty dependency array means this runs once on mount

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="cloud-diagrams-app">
        <div className="app">
          {/* Sidebar */}
          <div className="sidebar">
            <h2>Components</h2>
            <IconPalette onStartCreate={handleStartCreate} />
          </div>

          {/* Top Bar */}
          <div className="top-bar">
            <div className="button-group">
              <button className="top-bar-button primary" onClick={handleExportDiagram}>
                Export JSON
              </button>
              <ExportButton />
              <input
                type="file"
                ref={fileInputRef}
                onChange={handleLoadDiagram}
                accept=".json"
                className="hidden-file-input"
              />
              <button className="top-bar-button" onClick={handleLoadButtonClick}>
                Load Diagram
              </button>
            </div>

            <div className="button-group">
              <button className="top-bar-button" onClick={toggleLineType}>
                {isCurved ? 'Straight Lines' : 'Curved Lines'}
              </button>
              <button className="top-bar-button" onClick={handleToggleLabels}>
                {showConnectionLabels ? 'Hide Labels' : 'Show Labels'}
              </button>
              <button
                className="top-bar-button"
                onClick={() => setViewMode(viewMode === 'isometric' ? 'top-down' : 'isometric')}
              >
                {viewMode === 'isometric' ? 'Switch to 2D View' : 'Switch to 3D View'}
              </button>
            </div>

            <div className="button-group">
              <button 
                className="top-bar-button primary"
                onClick={() => setWorkflowModals({ ...workflowModals, create: true })}
              >
                Create Workflow
              </button>
              <button 
                className="top-bar-button"
                onClick={() => setShowWorkflows(!showWorkflows)}
              >
                View Workflows
              </button>
            </div>
          </div>

          {/* Workflows Panel */}
          {showWorkflows && (
            <div className="workflows-panel">
              <div className="workflows-header">
                <h3>Available Workflows</h3>
                <button onClick={() => setShowWorkflows(false)}>×</button>
              </div>
              <div className="workflows-list">
                {workflows.length === 0 ? (
                  <div className="no-workflows">
                    No workflows available. Create one to get started.
                  </div>
                ) : (
                  workflows.map(workflow => (
                    <div key={workflow.id} className="workflow-item">
                      <div className="workflow-item-header">
                        <span>{workflow.name}</span>
                        <div className="workflow-actions">
                          <button
                            className="workflow-action-button"
                            onClick={() => handleStartPresentation(workflow.id)}
                            title="Play Workflow"
                          >
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                              <polygon points="5 3 19 12 5 21 5 3"></polygon>
                            </svg>
                          </button>
                          <button
                            className="workflow-action-button"
                            onClick={() => {
                              setSelectedWorkflowId(workflow.id);
                              setWorkflowModals({ ...workflowModals, edit: true });
                            }}
                            title="Edit Workflow"
                          >
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                              <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>
                              <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path>
                            </svg>
                          </button>
                          <button
                            className="workflow-action-button"
                            onClick={() => handleDeleteWorkflow(workflow.id)}
                            title="Delete Workflow"
                          >
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                              <path d="M3 6h18"></path>
                              <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                            </svg>
                          </button>
                        </div>
                      </div>
                      {workflow.description && (
                        <div className="workflow-description">
                          {workflow.description}
                        </div>
                      )}
                    </div>
                  ))
                )}
              </div>
            </div>
          )}

          {/* Main Content */}
          <div className="main-content">
            <Canvas
              orthographic
              camera={{
                position: viewMode === 'isometric' ? [30, 35, 30] : [0, 100, 0],
                zoom: viewMode === 'isometric' ? 50 : 25, // Adjust zoom based on view
                near: -1000,
                far: 1000,
                up: [0, 1, 0],
              }}
              shadows
              style={{
                width: '100%',
                height: '100%',
                background: '#ffffff',
              }}
              onPointerMissed={() => {
                setSelectedIcon(null);
                setMenuOpenIconId(null);
                setMenuOpenConnectionId(null);
              }}
            >
              <SceneContent
                icons={icons}
                setIcons={setIcons}
                onIconClick={handleIconClick}
                connections={connections}
                setConnections={setConnections}
                selectedIcon={selectedIcon}
                menuOpenIconId={menuOpenIconId}
                onRename={handleRename}
                onStartMove={handleStartMove}
                onStartConnect={handleStartConnect}
                onStartChange={handleStartChange}
                movingIconId={movingIconId}
                onMoveEnd={handleMoveEnd}
                setIconPosition={setIconPosition}
                onConnectionClick={handleConnectionClick}
                menuOpenConnectionId={menuOpenConnectionId}
                onDeleteConnection={handleDeleteConnection}
                onEditConnection={handleEditConnection}
                isCurved={isCurved}
                onMenuToggle={handleMenuToggle}
                currentWorkflow={currentWorkflow}
                presentationMode={presentationMode}
                currentStepIndex={currentStepIndex}
                setCurrentStepIndex={setCurrentStepIndex}
                isPlaying={isPlaying}
                setIsPlaying={setIsPlaying}
                viewMode={viewMode}
                creatingIcon={creatingIcon}
                setCreatingIcon={setCreatingIcon}
                showConnectionLabels={showConnectionLabels}
              />
            </Canvas>
          </div>

          {/* Connection Modal */}
          {connectModalOpen && (
            <ConnectModal
              icons={icons}
              fromIcon={connectFromIcon}
              onCreateConnection={handleCreateConnection}
              onClose={() => {
                setConnectModalOpen(false);
                setConnectFromIcon(null);
              }}
            />
          )}

          {/* Connection Edit Modal */}
          {connectionModalOpen && (
            <ConnectionEditModal
              connection={connections[selectedConnectionIndex]}
              onUpdateConnection={(updatedConnection) => {
                setConnections((prevConnections) =>
                  prevConnections.map((conn, idx) =>
                    idx === selectedConnectionIndex
                      ? { ...conn, ...updatedConnection }
                      : conn
                  )
                );
                setConnectionModalOpen(false);
                setSelectedConnectionIndex(null);
              }}
              onClose={() => {
                setConnectionModalOpen(false);
                setSelectedConnectionIndex(null);
              }}
            />
          )}

          {/* Workflow Modals */}
          <WorkflowModals
            isOpen={workflowModals}
            onRequestClose={() =>
              setWorkflowModals({ create: false, edit: false })
            }
            onCreateWorkflow={handleCreateWorkflow}
            onEditWorkflow={handleEditWorkflow}
            icons={icons}
            connections={connections}
            selectedWorkflow={
              workflows.find((wf) => wf.id === selectedWorkflowId) || null
            }
          />

          {/* Change Icon Modal */}
          {changeModalOpen && iconToChange && (
            <ChangeIconModal
              isOpen={changeModalOpen}
              onRequestClose={() => {
                setChangeModalOpen(false);
                setIconToChange(null);
              }}
              iconToChange={iconToChange}
              onChangeIcon={handleChangeIcon}
            />
          )}
        </div>
      </div>
      {presentationMode && currentWorkflow && (
        <PresentationMode
          workflow={currentWorkflow}
          currentStepIndex={currentStepIndex}
          setCurrentStepIndex={setCurrentStepIndex}
          isPlaying={isPlaying}
          setIsPlaying={setIsPlaying}
          totalSteps={currentWorkflow.steps.length}
          onExit={handleExitPresentation}
        />
      )}
    </DndProvider>
  );
};

export default CloudDiagrams;
