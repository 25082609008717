// frontend/src/components/GridOverlay.js

import React from 'react';
import * as THREE from 'three';

const GridOverlay = ({ show }) => {
  if (!show) return null;

  const gridSize = 100;
  const snapSize = 5;

  const gridLines = [];
  for (let i = -gridSize / 2; i <= gridSize / 2; i += snapSize) {
    gridLines.push(
      <line
        key={`line-x-${i}`}
        position={[0, 0.01, 0]}
        geometry={new THREE.BufferGeometry().setFromPoints([
          new THREE.Vector3(-gridSize / 2, 0, i),
          new THREE.Vector3(gridSize / 2, 0, i),
        ])}
      >
        <lineBasicMaterial attach="material" color="yellow" />
      </line>
    );
    gridLines.push(
      <line
        key={`line-z-${i}`}
        position={[0, 0.01, 0]}
        geometry={new THREE.BufferGeometry().setFromPoints([
          new THREE.Vector3(i, 0, -gridSize / 2),
          new THREE.Vector3(i, 0, gridSize / 2),
        ])}
      >
        <lineBasicMaterial attach="material" color="yellow" />
      </line>
    );
  }

  return <group>{gridLines}</group>;
};

export default GridOverlay;
