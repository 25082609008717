const iconsData = [
  {
    category: "AWS - Databases",
    icons: ["RDS", "DynamoDB", "Redshift", "Aurora"],
  },
  {
    category: "AWS - Networking",
    icons: ["VPC", "Route53", "CloudFront", "ELB"],
  },
  {
    category: "AWS - Security",
    icons: ["IAM", "KMS", "Cognito", "WAF"],
  },
  {
    category: "AWS - Analytics & ML",
    icons: ["CloudWatch", "Kinesis", "SageMaker"],
  },
  {
    category: "AWS - Workflow",
    icons: ["StepFunctions"],
  },
  {
    category: "AWS - Core Services",
    icons: ["EC2", "S3", "APIGateway", "Lambda", "SQS", "SNS", "SFTP"],
  },
  {
    category: "Hashicorp",
    icons: ["Vault"],
  },
  {
    category: "Kubernetes",
    icons: ["Kubernetes"],
  },
  {
    category: "Databases",
    icons: ["SQL-Server", "MongoDB"],
  },
  {
    category: "Workload Automation",
    icons: ["ControlM"],
  },
  {
    category: "Web Apps",
    icons: ["React"],
  },
  {
    category: "Nginx",
    icons: ["Nginx"],
  },
  {
    category: "General",
    icons: ["Square", "Circle", "Person", "Computer"],
  },
  // Add more categories and icons as needed
];

export default iconsData;
