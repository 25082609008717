// frontend/src/components/DraggableIcon.js

import React from 'react';

const DraggableIcon = ({ name, onStartCreate }) => {
  const handleClick = () => {
    onStartCreate(name);
  };

  // Truncate name if longer than 20 characters
  const displayName = name.length > 20 ? name.substring(0, 20) + '...' : name;

  return (
    <div className="draggable-icon" onClick={handleClick} title={name}>
      <img
        src={`${process.env.PUBLIC_URL}/icons/${name}.png`}
        alt={name}
        width={50}
        height={50}
        style={{
          cursor: 'pointer',
          objectFit: 'contain',
        }}
      />
      <span className="icon-name">{displayName}</span>
    </div>
  );
};

export default DraggableIcon;
