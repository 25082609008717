import React, { useRef, useMemo } from 'react';
import { useFrame, useLoader } from '@react-three/fiber';
import * as THREE from 'three';

const WAFMesh = ({ isHighlighted }) => {
  const groupRef = useRef();
  const fireSprites = useRef([]);
  const fireTexture = useLoader(THREE.TextureLoader, '/textures/Fire.png'); // Path from public directory
  const wafTexture = useLoader(THREE.TextureLoader, '/icons/WAF.png'); // Load WAF texture

  // Colors
  const wallColor = isHighlighted ? '#8B4513' : '#A0522D'; // Brown shades for the wall

  // Create fire sprites
  const firePositions = useMemo(() => {
    const positions = [];
    for (let i = 0; i < 30; i++) {
      positions.push([
        (Math.random() - 0.5) * 2, // x
        Math.random() * 2,         // y
        0.1                        // z, slightly in front of the wall
      ]);
    }
    return positions;
  }, []);

  // Animation for fire effect
  useFrame(() => {
    fireSprites.current.forEach((sprite, index) => {
      sprite.position.y += 0.01; // Slower upward movement
      if (sprite.position.y > 2) sprite.position.y = 0;

      // Flicker effect
      const scale = 0.5 + Math.sin(index + sprite.position.y * 5) * 0.1;
      sprite.scale.set(scale, scale, scale);
      sprite.material.opacity = 0.6 + Math.sin(index + sprite.position.y * 5) * 0.3;
    });
  });

  return (
    <group ref={groupRef}>
      {/* Wall */}
      <mesh position={[0, 1, 0]} castShadow receiveShadow>
        <boxGeometry args={[2, 2, 0.2]} />
        <meshStandardMaterial color={wallColor} />
      </mesh>

      {/* Fire effect */}
      {firePositions.map((pos, index) => (
        <sprite
          key={index}
          ref={(el) => (fireSprites.current[index] = el)}
          position={pos}
          scale={[0.5, 0.5, 0.5]} // Initial scale
          rotation={[0, 0, Math.PI / 2]} // Align with the wall
        >
          <spriteMaterial map={fireTexture} transparent opacity={0.8} />
        </sprite>
      ))}

      {/* Fixed 2D WAF Logo on the grid */}
      <mesh position={[2.5, 0.01, 1.5]} rotation={[-Math.PI / 2, 0, 0]}>
        <planeGeometry args={[0.8, 0.8]} />
        <meshBasicMaterial map={wafTexture} transparent side={THREE.DoubleSide} />
      </mesh>
    </group>
  );
};

export default WAFMesh;
