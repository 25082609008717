// src/components/Animations/EncryptionAnimation.js

import React, { useRef } from 'react';
import { useFrame } from '@react-three/fiber';

const EncryptionAnimation = ({ position }) => {
  const groupRef = useRef();
  const shackleRef = useRef();

  // Animation state
  const animationProgress = useRef(0);

  // Adjusted duration to match the presentation step duration
  const duration = 4; // Total animation duration in seconds

  useFrame((state, delta) => {
    if (groupRef.current) {
      animationProgress.current += delta;
      const t = Math.min(animationProgress.current / duration, 1);

      // Animate the shackle from open to closed
      const shackleRotation = Math.PI * (1 - t); // Rotate from PI to 0
      shackleRef.current.rotation.z = shackleRotation;
    }
  });

  return (
    <group ref={groupRef} position={position}>
      {/* Lock Base */}
      <mesh position={[0, 0, 0]}>
        <boxGeometry args={[0.6, 0.8, 0.2]} />
        <meshStandardMaterial color="gold" />
      </mesh>
      {/* Lock Shackle */}
      <mesh ref={shackleRef} position={[0, 0.7, 0]}>
        <cylinderGeometry args={[0.15, 0.15, 0.8, 32]} />
        <meshStandardMaterial color="silver" />
      </mesh>
    </group>
  );
};

export default EncryptionAnimation;
