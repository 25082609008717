import React, { useMemo, useEffect } from "react";
import * as THREE from "three";
import { useFrame, useLoader } from "@react-three/fiber";

const DatabaseMesh = ({ iconType, isHighlighted }) => {
  // Color configurations - define your colors here directly
  const colorConfigs = useMemo(() => ({
    DynamoDB: {
      body: isHighlighted ? "#3498DB" : "#2980B9", 
      glow: "#00FF00",
      text: "#ECF0F1",
    },
    MongoDB: {
      body: isHighlighted ? "#4CAF50" : "#388E3C", 
      glow: "#FFD700",
      text: "#F0F4C3",
    },
    RDS: {
      body: isHighlighted ? "#1E90FF" : "#0073BB", 
      glow: "#00CED1",
      text: "#E1F5FE",
    },
    Redshift: {
      body: isHighlighted ? "#8E44AD" : "#6A1B9A", 
      glow: "#FF69B4",
      text: "#F3E5F5",
    },
    Aurora: {
      body: isHighlighted ? "#FF8C00" : "#FF7F50", 
      glow: "#FF4500",
      text: "#FFF3E0",
    },
    "SQL-Server": {
      body: isHighlighted ? "#DC143C" : "#8B0000", // Example colors for SQL Server
      glow: "#FFA500", 
      text: "#FFFFFF",
    },
  }), [isHighlighted]); 

  const colors = colorConfigs[iconType] || colorConfigs.DynamoDB;

  // Custom shader material for glowing effect
  const glowingMaterial = useMemo(() => {
    return new THREE.ShaderMaterial({
      uniforms: {
        time: { value: 0 },
        color: { value: new THREE.Color(colors.glow) },
      },
      vertexShader: `
        varying vec2 vUv;
        void main() {
          vUv = uv;
          gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
        }
      `,
      fragmentShader: `
        uniform float time;
        uniform vec3 color;
        varying vec2 vUv;
        void main() {
          float intensity = 0.5 + 0.5 * sin(vUv.x * 20.0 + time * 2.0);
          gl_FragColor = vec4(color * intensity, 0.7);
        }
      `,
      transparent: true,
      side: THREE.DoubleSide,
    });
  }, [colors.glow]);

  // Animation for glowing effect
  useFrame(({ clock }) => {
    glowingMaterial.uniforms.time.value = clock.getElapsedTime();
  });

  // Icon mapping
  const iconMapping = useMemo(() => ({
    DynamoDB: `${process.env.PUBLIC_URL}/icons/DynamoDB.png`,
    MongoDB: `${process.env.PUBLIC_URL}/icons/MongoDB.png`,
    RDS: `${process.env.PUBLIC_URL}/icons/RDS.png`,
    Redshift: `${process.env.PUBLIC_URL}/icons/Redshift.png`,
    Aurora: `${process.env.PUBLIC_URL}/icons/Aurora.png`,
    "SQL-Server": `${process.env.PUBLIC_URL}/icons/SQL-Server.png`,
  }), []);

  // Load texture
  const texture = useLoader(THREE.TextureLoader, iconMapping[iconType]);

  // Create sign mesh with a nicer look
  const signMesh = useMemo(() => {
    const signGeometry = new THREE.BoxGeometry(0.8, 0.6, 0.05);
    const signMaterial = new THREE.MeshPhongMaterial({ 
      color: '#FFFFFF',
      specular: 0x050505,
      shininess: 100
    });
    return new THREE.Mesh(signGeometry, signMaterial);
  }, []);

  // Create post mesh
  const postMesh = useMemo(() => {
    const postGeometry = new THREE.CylinderGeometry(0.03, 0.03, 1.5, 8);
    const postMaterial = new THREE.MeshStandardMaterial({ color: '#8B4513' });
    return new THREE.Mesh(postGeometry, postMaterial);
  }, []);

  // Create icon mesh
  const iconMesh = useMemo(() => {
    const iconGeometry = new THREE.PlaneGeometry(1, 0.6);
    const iconMaterial = new THREE.MeshBasicMaterial({ 
      map: texture,
      transparent: true,
      side: THREE.DoubleSide
    });
    return new THREE.Mesh(iconGeometry, iconMaterial);
  }, [texture]);

  return (
    <group>
      {[0, 0.8, 1.6, 2.4].map((yPos, index) => (
        <group key={index} position={[0, yPos, 0]}>
          <mesh castShadow receiveShadow>
            <cylinderGeometry args={[1.2, 1.2, 0.7, 32]} />
            <meshStandardMaterial color={colors.body} />
          </mesh>

          {[-0.25, 0, 0.25].map((ringY, ringIndex) => (
            <mesh
              key={ringIndex}
              position={[0, ringY, 0]}
              rotation={[Math.PI / 2, 0, 0]}
            >
              <torusGeometry args={[1.21, 0.02, 16, 100]} />
              <primitive object={glowingMaterial} />
            </mesh>
          ))}
        </group>
      ))}

      <mesh position={[0, 2.75, 0]} castShadow receiveShadow>
        <cylinderGeometry args={[1.2, 1.2, 0.1, 32]} />
        <meshStandardMaterial color={colors.body} />
      </mesh>

      {/* Sign with post and icon */}
      <group position={[1.6, 0, 0]} rotation={[0, Math.PI / 4, 0]}>
        {/* Post */}
        <primitive object={postMesh} position={[0, 0.75, 0]} />
        
        {/* Sign */}
        <primitive object={signMesh} position={[0, 1.6, 0]} rotation={[0, 0, 0]} />
        
        {/* Icon on sign */}
        <primitive 
          object={iconMesh} 
          position={[0, 1.6, 0.028]}
          rotation={[0, 0, 0]}
          scale={[0.75, 1, 1]}  // Further increased vertical scale
        />
      </group>
    </group>
  );
};

export default DatabaseMesh;
