// src/components/Modals/WorkflowModals.js

import React, { useState, useRef } from 'react';
import Modal from 'react-modal';
import { useDrag, useDrop } from 'react-dnd';
import { FaTimes, FaArrowRight, FaPlay, FaPlus } from 'react-icons/fa';
import './WorkflowModals.css';

// Set the app element for accessibility
Modal.setAppElement('#root');

export const WorkflowModals = ({
  isOpen,
  onRequestClose,
  onCreateWorkflow,
  onEditWorkflow,
  icons,
  connections,
  selectedWorkflow,
}) => {
  return (
    <>
      {isOpen.create && (
        <WorkflowCreateModal
          isOpen={isOpen.create}
          onRequestClose={onRequestClose}
          onCreateWorkflow={onCreateWorkflow}
          icons={icons}
          connections={connections}
        />
      )}
      {isOpen.edit && selectedWorkflow && (
        <WorkflowEditModal
          isOpen={isOpen.edit}
          onRequestClose={onRequestClose}
          onEditWorkflow={onEditWorkflow}
          icons={icons}
          connections={connections}
          selectedWorkflow={selectedWorkflow}
        />
      )}
    </>
  );
};

const WorkflowCreateModal = ({
  isOpen,
  onRequestClose,
  onCreateWorkflow,
  icons,
  connections,
}) => {
  const [name, setName] = useState('');
  const [steps, setSteps] = useState([]);

  const handleAddStep = (step) => {
    setSteps([...steps, step]);
  };

  const handleRemoveStep = (path) => {
    setSteps((prevSteps) => removeStepAtPath(prevSteps, path));
  };

  const handleSubmit = () => {
    if (name.trim() === '') {
      alert('Please enter a workflow name.');
      return;
    }
    const newWorkflow = {
      id: Date.now(),
      name,
      steps,
    };
    onCreateWorkflow(newWorkflow);
    onRequestClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Create Workflow"
      className="workflow-modal"
      overlayClassName="workflow-modal-overlay"
      shouldCloseOnOverlayClick={false}
    >
      <h2>Create New Workflow</h2>
      <form onSubmit={(e) => e.preventDefault()}>
        <label>
          Workflow Name:
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Enter workflow name"
          />
        </label>
        <WorkflowStepsManager
          steps={steps}
          setSteps={setSteps}
          onAddStep={handleAddStep}
          onRemoveStep={handleRemoveStep}
          icons={icons}
          connections={connections}
        />
        <div className="modal-buttons">
          <button
            type="button"
            onClick={handleSubmit}
            className="primary-button"
          >
            Create Workflow
          </button>
          <button
            type="button"
            onClick={onRequestClose}
            className="secondary-button"
          >
            Cancel
          </button>
        </div>
      </form>
    </Modal>
  );
};

const WorkflowEditModal = ({
  isOpen,
  onRequestClose,
  onEditWorkflow,
  icons,
  connections,
  selectedWorkflow,
}) => {
  const [name, setName] = useState(selectedWorkflow.name);
  const [steps, setSteps] = useState(selectedWorkflow.steps);

  const handleAddStep = (step) => {
    setSteps([...steps, step]);
  };

  const handleRemoveStep = (path) => {
    setSteps((prevSteps) => removeStepAtPath(prevSteps, path));
  };

  const handleSubmit = () => {
    if (name.trim() === '') {
      alert('Please enter a workflow name.');
      return;
    }
    const updatedWorkflow = {
      ...selectedWorkflow,
      name,
      steps,
    };
    onEditWorkflow(updatedWorkflow);
    onRequestClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Edit Workflow"
      className="workflow-modal"
      overlayClassName="workflow-modal-overlay"
      shouldCloseOnOverlayClick={false}
    >
      <h2>Edit Workflow</h2>
      <form onSubmit={(e) => e.preventDefault()}>
        <label>
          Workflow Name:
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Enter workflow name"
          />
        </label>
        <WorkflowStepsManager
          steps={steps}
          setSteps={setSteps}
          onAddStep={handleAddStep}
          onRemoveStep={handleRemoveStep}
          icons={icons}
          connections={connections}
        />
        <div className="modal-buttons">
          <button
            type="button"
            onClick={handleSubmit}
            className="primary-button"
          >
            Update Workflow
          </button>
          <button
            type="button"
            onClick={onRequestClose}
            className="secondary-button"
          >
            Cancel
          </button>
        </div>
      </form>
    </Modal>
  );
};

// Helper functions for nested steps
const removeStepAtPath = (steps, path) => {
  if (!Array.isArray(steps)) steps = [];

  if (path.length === 1) {
    return steps.filter((_, i) => i !== path[0]);
  } else {
    const [index, ...rest] = path;
    return steps.map((step, i) => {
      if (i === index) {
        return {
          ...step,
          parallelSteps: removeStepAtPath(step.parallelSteps || [], rest),
        };
      } else {
        return step;
      }
    });
  }
};

const insertStepAtPath = (steps, path, stepToInsert) => {
  if (!Array.isArray(steps)) steps = [];

  if (path.length === 1) {
    const newSteps = [...steps];
    newSteps.splice(path[0], 0, stepToInsert);
    return newSteps;
  } else {
    const [index, ...rest] = path;
    return steps.map((step, i) => {
      if (i === index) {
        return {
          ...step,
          parallelSteps: insertStepAtPath(
            step.parallelSteps || [],
            rest,
            stepToInsert
          ),
        };
      } else {
        return step;
      }
    });
  }
};

const addParallelStepAtPath = (steps, path, stepToAdd) => {
  if (!Array.isArray(steps)) steps = [];

  if (path.length === 0) return steps; // Can't add parallel step at root level

  const [index, ...rest] = path;
  return steps.map((step, i) => {
    if (i === index) {
      if (rest.length === 0) {
        // We're at the target step
        return {
          ...step,
          parallelSteps: [...(step.parallelSteps || []), stepToAdd],
        };
      } else {
        return {
          ...step,
          parallelSteps: addParallelStepAtPath(
            step.parallelSteps || [],
            rest,
            stepToAdd
          ),
        };
      }
    } else {
      return step;
    }
  });
};

const getStepAtPath = (steps, path) => {
  if (!Array.isArray(steps)) steps = [];
  let currentSteps = steps;
  let step = null;
  for (let index of path) {
    if (!currentSteps[index]) return null;
    step = currentSteps[index];
    currentSteps = step.parallelSteps || [];
  }
  return step;
};

const WorkflowStepsManager = ({
  steps,
  setSteps,
  onAddStep,
  onRemoveStep,
  icons,
  connections,
}) => {
  const [selectedComponentId, setSelectedComponentId] = useState('');
  const [selectedAnimation, setSelectedAnimation] = useState('');
  const [selectedConnectionId, setSelectedConnectionId] = useState('');

  const [addParallelToPath, setAddParallelToPath] = useState(null);

  const handleAddAnimationStep = () => {
    if (!selectedComponentId || !selectedAnimation) {
      alert('Please select a component and an animation.');
      return;
    }

    const component = icons.find(
      (icon) => icon.id.toString() === selectedComponentId
    );

    if (!component) {
      alert('Invalid component selected.');
      return;
    }

    const step = {
      id: Date.now(),
      type: 'animation',
      targetId: component.id,
      animation: selectedAnimation,
      name: `${selectedAnimation} on ${component.name}`,
    };

    onAddStep(step);
    setSelectedComponentId('');
    setSelectedAnimation('');
  };

  const handleAddConnectionStep = () => {
    if (selectedConnectionId === '') {
      alert('Please select a connection.');
      return;
    }
    const connection = connections.find(
      (conn) => conn.id.toString() === selectedConnectionId
    );
    if (!connection) {
      alert('Invalid connection selected.');
      return;
    }
    const startIcon = icons.find((icon) => icon.id === connection.startId);
    const endIcon = icons.find((icon) => icon.id === connection.endId);
    const step = {
      id: Date.now(),
      type: 'connection',
      targetId: connection.id,
      name: `${startIcon ? startIcon.name : 'Unknown'} → ${
        endIcon ? endIcon.name : 'Unknown'
      }`,
    };
    onAddStep(step);
    setSelectedConnectionId('');
  };

  const moveStep = (dragPath, hoverPath) => {
    if (JSON.stringify(dragPath) === JSON.stringify(hoverPath)) {
      return;
    }

    setSteps((prevSteps) => {
      const dragStep = getStepAtPath(prevSteps, dragPath);
      if (!dragStep) return prevSteps; // Prevent errors
      let newSteps = removeStepAtPath(prevSteps, dragPath);
      newSteps = insertStepAtPath(newSteps, hoverPath, dragStep);
      return newSteps;
    });
  };

  const addParallelStep = (dragPath) => {
    if (!addParallelToPath) return;

    setSteps((prevSteps) => {
      const dragStep = getStepAtPath(prevSteps, dragPath);
      if (!dragStep) return prevSteps; // Prevent errors
      let newSteps = removeStepAtPath(prevSteps, dragPath);
      newSteps = addParallelStepAtPath(newSteps, addParallelToPath, dragStep);
      return newSteps;
    });

    setAddParallelToPath(null);
  };

  const renderSteps = (stepsArray, path = []) => {
    if (!Array.isArray(stepsArray)) stepsArray = [];
    return stepsArray.map((step, index) => {
      const currentPath = [...path, index];
      return (
        <div key={currentPath.join('-')} style={{ marginLeft: path.length * 20 }}>
          <StepItem
            step={step}
            index={index}
            path={currentPath}
            onRemoveStep={onRemoveStep}
            moveStep={moveStep}
            onAddParallelStep={() => setAddParallelToPath(currentPath)}
            addParallelToPath={addParallelToPath}
            addParallelStep={addParallelStep}
          />
          {step.parallelSteps && step.parallelSteps.length > 0 && (
            <div className="parallel-steps">
              {renderSteps(step.parallelSteps, currentPath)}
            </div>
          )}
        </div>
      );
    });
  };

  return (
    <div className={`workflow-steps-manager ${addParallelToPath ? 'add-parallel-mode' : ''}`}>
      <h3>Steps</h3>
      {addParallelToPath && (
        <div className="add-parallel-message">
          Drag a step onto the highlighted step to add it as a parallel step.
          <button type="button" onClick={() => setAddParallelToPath(null)} className="cancel-button">
            Cancel
          </button>
        </div>
      )}
      <ul className="steps-list">{renderSteps(steps)}</ul>
      <div className="add-step-container">
        <div className="select-group">
          <select
            value={selectedComponentId}
            onChange={(e) => setSelectedComponentId(e.target.value)}
          >
            <option value="">-- Select Component --</option>
            {icons.map((icon) => (
              <option key={icon.id} value={icon.id}>
                {icon.name}
              </option>
            ))}
          </select>
          <select
            value={selectedAnimation}
            onChange={(e) => setSelectedAnimation(e.target.value)}
          >
            <option value="">-- Select Animation --</option>
            <option value="encryption">Encryption</option>
            <option value="authentication">Authentication</option>
          </select>
        </div>
        <button
          type="button"
          onClick={handleAddAnimationStep}
          className="add-step-button"
        >
          Add Animation Step
        </button>
      </div>
      <div className="add-step-container">
        <select
          value={selectedConnectionId}
          onChange={(e) => setSelectedConnectionId(e.target.value)}
        >
          <option value="">-- Select Connection --</option>
          {connections.map((conn) => {
            const startIcon = icons.find((icon) => icon.id === conn.startId);
            const endIcon = icons.find((icon) => icon.id === conn.endId);
            return (
              <option key={conn.id} value={conn.id}>
                {startIcon ? startIcon.name : 'Unknown'} →{' '}
                {endIcon ? endIcon.name : 'Unknown'}
              </option>
            );
          })}
        </select>
        <button
          type="button"
          onClick={handleAddConnectionStep}
          className="add-step-button"
        >
          Add Connection Step
        </button>
      </div>
    </div>
  );
};

const StepItem = ({
  step,
  index,
  path,
  onRemoveStep,
  moveStep,
  onAddParallelStep,
  addParallelToPath,
  addParallelStep,
}) => {
  const ref = useRef(null);

  const [{ isOver, canDrop }, drop] = useDrop({
    accept: 'STEP',
    drop(item, monitor) {
      const dragPath = item.path;

      if (
        addParallelToPath &&
        JSON.stringify(path) === JSON.stringify(addParallelToPath)
      ) {
        addParallelStep(dragPath);
      } else {
        moveStep(dragPath, path);
        item.path = path;
      }
    },
    canDrop: (item, monitor) => {
      const dragPath = item.path;
      const hoverPath = path;
      return JSON.stringify(dragPath) !== JSON.stringify(hoverPath);
    },
    collect: (monitor) => ({
      isOver: monitor.isOver({ shallow: true }),
      canDrop: monitor.canDrop(),
    }),
  });

  const [{ isDragging }, drag] = useDrag({
    type: 'STEP',
    item: { type: 'STEP', id: step.id, path },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));

  const handleRemove = () => {
    onRemoveStep(path);
  };

  const handleAddParallelClick = (e) => {
    e.stopPropagation();
    onAddParallelStep();
  };

  // Determine if this is the target step for adding parallel steps
  const isTargetStep =
    addParallelToPath && JSON.stringify(path) === JSON.stringify(addParallelToPath);

  return (
    <li
      ref={ref}
      className={`steps-list-item ${isDragging ? 'dragging' : ''} ${
        isOver && canDrop ? 'hovered' : ''
      } ${isTargetStep ? 'target-step' : ''}`}
    >
      <span className="step-number">{index + 1}.</span>
      <span className="step-name">
        {step.type === 'connection' ? (
          <>
            <FaArrowRight className="step-icon" />
            {step.name}
          </>
        ) : step.type === 'animation' ? (
          <>
            <FaPlay className="step-icon" />
            {step.name}
          </>
        ) : (
          <>
            {/* Handle other step types */}
            {step.name}
          </>
        )}
      </span>
      <div className="step-actions">
        <button
          type="button"
          className={`add-parallel-step-button ${
            isTargetStep ? 'active' : ''
          }`}
          onClick={handleAddParallelClick}
        >
          <FaPlus />
        </button>
        <button type="button" className="remove-step-button" onClick={handleRemove}>
          <FaTimes />
        </button>
      </div>
    </li>
  );
};

export default WorkflowModals;
