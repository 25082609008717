import React, { useRef } from 'react';
import { useFrame, useLoader } from '@react-three/fiber';
import { Text } from '@react-three/drei';
import * as THREE from 'three';

const NginxMesh = ({ isHighlighted }) => {
  const groupRef = useRef();
  const dataParticlesRef = useRef([]);
  
  // Colors
  const proxyColor = isHighlighted ? '#00c853' : '#00e676'; // Lighter green
  const particleColor = '#69f0ae'; // Lighter particle color
  const pipeColor = '#9e9e9e'; // Lighter grey for pipes
  
  // Load Nginx texture
  const nginxTexture = useLoader(THREE.TextureLoader, `${process.env.PUBLIC_URL}/icons/Nginx.png`);
  
  // Create data particles for animation
  const particleCount = 5;
  if (dataParticlesRef.current.length === 0) {
    for (let i = 0; i < particleCount; i++) {
      dataParticlesRef.current.push({
        position: new THREE.Vector3(-2, 0, 0),
        progress: i * (1 / particleCount),
      });
    }
  }

  // Animation for data flow
  useFrame((state, delta) => {
    dataParticlesRef.current.forEach(particle => {
      particle.progress = (particle.progress + delta * 0.5) % 1;
      
      if (particle.progress < 0.5) {
        particle.position.x = THREE.MathUtils.lerp(-2, 0, particle.progress * 2);
      } else {
        particle.position.x = THREE.MathUtils.lerp(0, 2, (particle.progress - 0.5) * 2);
      }
    });
  });

  return (
    <group ref={groupRef}>
      {/* Ambient light to brighten the whole scene */}
      <ambientLight intensity={0.5} />
      
      {/* Directional light for better definition */}
      <directionalLight
        position={[5, 5, 5]}
        intensity={0.8}
        castShadow
      />

      {/* Main Proxy Body */}
      <mesh position={[0, 1, 0]} castShadow receiveShadow>
        <octahedronGeometry args={[1, 0]} />
        <meshStandardMaterial 
          color={proxyColor}
          metalness={0.3}
          roughness={0.4}
          emissive={proxyColor}
          emissiveIntensity={0.2}
        />
      </mesh>

      {/* Incoming Pipe */}
      <mesh position={[-1.5, 1, 0]} rotation={[0, 0, Math.PI / 2]}>
        <cylinderGeometry args={[0.1, 0.1, 1, 16]} />
        <meshStandardMaterial 
          color={pipeColor}
          metalness={0.3}
          roughness={0.4}
        />
      </mesh>

      {/* Outgoing Pipe */}
      <mesh position={[1.5, 1, 0]} rotation={[0, 0, Math.PI / 2]}>
        <cylinderGeometry args={[0.1, 0.1, 1, 16]} />
        <meshStandardMaterial 
          color={pipeColor}
          metalness={0.3}
          roughness={0.4}
        />
      </mesh>

      {/* Data Flow Particles */}
      {dataParticlesRef.current.map((particle, index) => (
        <mesh
          key={index}
          position={[particle.position.x, 1, 0]}
          scale={0.2}
        >
          <sphereGeometry args={[0.2, 16, 16]} />
          <meshBasicMaterial
            color={particleColor}
            transparent
            opacity={0.8}
          />
        </mesh>
      ))}

      {/* Labels */}
      <Text
        position={[-1.5, 0.3, 0]}
        fontSize={0.3}
        color="black"
        anchorX="center"
        anchorY="middle"
      >
        Client
      </Text>

      <Text
        position={[1.5, 0.3, 0]}
        fontSize={0.3}
        color="black"
        anchorX="center"
        anchorY="middle"
      >
        Server
      </Text>

      {/* 2D Nginx Logo on the grid */}
      <mesh position={[2.5, 0.01, 1.5]} rotation={[-Math.PI / 2, 0, 0]}>
        <planeGeometry args={[0.8, 0.8]} />
        <meshBasicMaterial map={nginxTexture} transparent side={THREE.DoubleSide} />
      </mesh>
    </group>
  );
};

export default NginxMesh;
