import React, { useRef, useMemo } from 'react';
import { useFrame } from '@react-three/fiber';
import * as THREE from 'three';
import { Text } from '@react-three/drei';

const FileMesh = ({ isHighlighted }) => {
  const groupRef = useRef();
  const folderRef = useRef();

  const serverColor = isHighlighted ? '#4CAF50' : '#2E7D32'; // Green colors
  const folderColor = '#FFA000'; // Amber color for folder

  // Animation parameters
  const animationDuration = 3; // 3 seconds for full animation cycle
  const folderStartPosition = new THREE.Vector3(3, 1, 0);
  const folderEndPosition = new THREE.Vector3(0, 1, 0);

  // Custom animation curve
  const curve = useMemo(() => {
    return new THREE.CatmullRomCurve3([
      folderStartPosition,
      new THREE.Vector3(1.5, 2, 0),
      folderEndPosition
    ]);
  }, []);

  useFrame((state, delta) => {
    if (groupRef.current && folderRef.current) {
      const elapsedTime = state.clock.getElapsedTime() % animationDuration;
      const progress = elapsedTime / animationDuration;

      const position = curve.getPoint(progress);
      folderRef.current.position.copy(position);
      folderRef.current.rotation.y = progress * Math.PI * 2;

      const scale = 1 - progress * 0.5;
      folderRef.current.scale.set(scale, scale, scale);

      if (progress > 0.8) {
        const opacity = 1 - (progress - 0.8) * 5;
        folderRef.current.children.forEach(child => {
          if (child.material) {
            child.material.opacity = opacity;
          }
        });
      } else {
        folderRef.current.children.forEach(child => {
          if (child.material) {
            child.material.opacity = 1;
          }
        });
      }
    }
  });

  return (
    <group ref={groupRef}>
      {/* Server */}
      <mesh position={[0, 1, 0]} castShadow receiveShadow>
        <boxGeometry args={[2, 2, 1]} />
        <meshStandardMaterial color={serverColor} />
      </mesh>
      
      {/* Server front panel */}
      <mesh position={[0, 1, 0.51]} castShadow>
        <planeGeometry args={[1.8, 1.8]} />
        <meshStandardMaterial color="#1A237E" />
      </mesh>

      {/* Server rack slots */}
      {[-0.6, -0.2, 0.2, 0.6].map((y, index) => (
        <mesh key={index} position={[0, y + 1, 0.52]} castShadow>
          <planeGeometry args={[1.6, 0.3]} />
          <meshStandardMaterial color="#0D47A1" />
        </mesh>
      ))}
      
      {/* Server lights */}
      {[0.7, 0, -0.7].map((y, index) => (
        <mesh key={index} position={[0.8, y + 1, 0.52]} castShadow>
          <circleGeometry args={[0.05, 32]} />
          <meshStandardMaterial color={isHighlighted ? ["#00FF00", "#FFFF00", "#FF0000"][index] : "#444444"} emissive={isHighlighted ? ["#00FF00", "#FFFF00", "#FF0000"][index] : "#444444"} />
        </mesh>
      ))}

      {/* Server text */}
      <Text
        position={[0, 1.7, 0.52]}
        scale={[0.2, 0.2, 0.2]}
        color="white"
        anchorX="center"
        anchorY="middle"
      >
        SFTP SERVER
      </Text>

      <Text
        position={[0, 0.3, 0.52]}
        scale={[0.1, 0.1, 0.1]}
        color="white"
        anchorX="center"
        anchorY="middle"
      >
        192.168.1.100:22
      </Text>

      {/* Animated Folder */}
      <group ref={folderRef} position={folderStartPosition}>
        {/* Folder body */}
        <mesh castShadow>
          <boxGeometry args={[0.8, 0.6, 0.1]} />
          <meshStandardMaterial color={folderColor} transparent />
        </mesh>

        {/* Folder tab */}
        <mesh position={[0, 0.3, 0]} castShadow>
          <boxGeometry args={[0.4, 0.1, 0.1]} />
          <meshStandardMaterial color={folderColor} transparent />
        </mesh>

        {/* Folder lines (simulating papers) */}
        {[-0.1, 0, 0.1].map((y, index) => (
          <mesh key={index} position={[0, y, 0.051]} castShadow>
            <planeGeometry args={[0.6, 0.02]} />
            <meshStandardMaterial color="#FFFFFF" transparent />
          </mesh>
        ))}
      </group>
    </group>
  );
};

export default FileMesh;