import React, { useRef } from 'react';
import { useLoader } from '@react-three/fiber';
import * as THREE from 'three';

const VaultMesh = ({ isHighlighted }) => {
  const groupRef = useRef();
  const shackleRef = useRef();

  // Updated to a lighter tan color
  const bodyColor = isHighlighted ? '#F5E1A4' : '#F5DEB3'; // Lightened Tan color
  const shackleColor = '#4A4A4A'; // Dark gray for shackle

  // Load Vault texture
  const vaultTexture = useLoader(THREE.TextureLoader, `${process.env.PUBLIC_URL}/icons/Vault.png`);

  return (
    <group ref={groupRef}>
      {/* Vault Body */}
      <mesh castShadow receiveShadow>
        <boxGeometry args={[1.5, 2, 0.8]} />
        <meshStandardMaterial color={bodyColor} metalness={0.1} roughness={0.8} />
      </mesh>

      {/* Keyhole */}
      <mesh position={[0, -0.3, 0.41]} castShadow receiveShadow>
        <cylinderGeometry args={[0.2, 0.2, 0.05, 32]} />
        <meshStandardMaterial color={shackleColor} metalness={0.3} roughness={0.7} />
      </mesh>
      <mesh position={[0, -0.6, 0.41]} castShadow receiveShadow>
        <boxGeometry args={[0.1, 0.4, 0.05]} />
        <meshStandardMaterial color={shackleColor} metalness={0.3} roughness={0.7} />
      </mesh>

      {/* Shackle */}
      <group ref={shackleRef} position={[0.55, 1.1, 0]}>
        <mesh position={[-0.55, 0, 0]} castShadow receiveShadow>
          <torusGeometry args={[0.4, 0.08, 16, 100, Math.PI]} />
          <meshStandardMaterial color={shackleColor} metalness={0.5} roughness={0.5} />
        </mesh>
        {/* Shackle legs */}
        <mesh position={[-0.95, -0.4, 0]} castShadow receiveShadow>
          <cylinderGeometry args={[0.08, 0.08, 0.8, 16]} />
          <meshStandardMaterial color={shackleColor} metalness={0.5} roughness={0.5} />
        </mesh>
        <mesh position={[-0.15, -0.4, 0]} castShadow receiveShadow>
          <cylinderGeometry args={[0.08, 0.08, 0.8, 16]} />
          <meshStandardMaterial color={shackleColor} metalness={0.5} roughness={0.5} />
        </mesh>
      </group>

      {/* Fixed 2D Vault Logo on the grid */}
      <mesh position={[2.5, 0.01, 1.5]} rotation={[-Math.PI / 2, 0, 0]}>
        <planeGeometry args={[0.8, 0.8]} />
        <meshBasicMaterial map={vaultTexture} transparent side={THREE.DoubleSide} />
      </mesh>
    </group>
  );
};

export default VaultMesh;
