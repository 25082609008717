// src/components/Workflows/PresentationMode.js

import React from 'react';
// import './PresentationMode.css';

const PresentationMode = ({
  workflow,
  onExit,
  currentStepIndex,
  setCurrentStepIndex,
  isPlaying,
  setIsPlaying,
}) => {
  const handleNext = () => {
    if (currentStepIndex < workflow.steps.length - 1) {
      setCurrentStepIndex(currentStepIndex + 1);
    } else {
      setIsPlaying(false);
    }
  };

  const handlePrevious = () => {
    if (currentStepIndex > 0) {
      setCurrentStepIndex(currentStepIndex - 1);
    }
  };

  const handlePlayPause = () => {
    setIsPlaying(!isPlaying);
  };

  return (
    <div className="presentation-controls">
      <button onClick={handlePrevious} disabled={currentStepIndex === 0}>
        Previous
      </button>
      <button onClick={handlePlayPause}>
        {isPlaying ? 'Pause' : 'Play'}
      </button>
      <button
        onClick={handleNext}
        disabled={currentStepIndex >= workflow.steps.length - 1}
      >
        Next
      </button>
      <button onClick={onExit}>Exit Presentation</button>
    </div>
  );
};

export default PresentationMode;
