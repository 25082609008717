import React from 'react';
import { Link } from 'react-router-dom';
import './LandingPage.css';
import { FaInstagram, FaLinkedin, FaDownload } from 'react-icons/fa'; // Import Instagram, LinkedIn, and Download icons

function LandingPage() {
    return (
        <div className="landing-page">
            <header className="header">
                <h1>Zachary Marks</h1>
                <p>Exploring the Future of Development with AI Tools</p>
            </header>
            <main className="content">
                <section className="projects">
                    <h2>Featured Project: Cloud Infrastructure Diagramming Tool</h2>
                    <Link to="/cloud-diagrams">
                        <img 
                            src="/images/CloudDiagram.png" 
                            alt="Cloud Diagram" 
                            className="project-image"
                        />
                    </Link>
                    <p className="image-caption">
                        The diagram above shows the infrastructure defined in available-meshes.json
                    </p>
                    <h3>3D Isometric Cloud Architecture Visualization</h3>
                    <p>
                        My first major AI-assisted project is a dynamic infrastructure diagramming tool that allows users to create and visualize cloud architectures in 3D isometric view. Built with React and Three.js, this tool demonstrates how AI can assist in developing complex interactive applications. All of the code for the diagram tool including the 3D assets were made entirely with AI.
                    </p>
                    <ul>
                        <li>Interactive 3D visualization of cloud components</li>
                        <li>Drag-and-drop interface for easy component placement</li>
                        <li>Export diagrams as PNG or JSON for sharing</li>
                        <li>Create animated workflows to showcase infrastructure changes</li>
                        <li>Switch between 3D and 2D views</li>
                        <li className="not-mobile-friendly">Not optimized for mobile yet</li>
                    </ul>
                    <div className="download-section">
                        <h2>Example Files</h2>
                        <p>Download these example files or try them directly in the diagram tool:</p>
                        <div className="download-links">
                            <div className="button-group">
                                <a 
                                    href="/json/public-site-infra.json" 
                                    download 
                                    className="download-link"
                                >
                                    <FaDownload className="download-icon" />
                                    <span>public-site-infra.json</span>
                                </a>
                                <Link 
                                    to="/cloud-diagrams?load=public-site-infra.json" 
                                    className="try-link"
                                >
                                    Try in Diagram Tool ▶
                                </Link>
                            </div>
                            <div className="button-group">
                                <a 
                                    href="/json/available-meshes.json" 
                                    download 
                                    className="download-link"
                                >
                                    <FaDownload className="download-icon" />
                                    <span>available-meshes.json</span>
                                </a>
                                <Link 
                                    to="/cloud-diagrams?load=available-meshes.json" 
                                    className="try-link"
                                >
                                    Try in Diagram Tool ▶
                                </Link>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="approach">
                    <h2>My Approach to AI-Assisted Development</h2>
                    <div className="approach-item">
                        <h3>Code Generation</h3>
                        <p>Leveraging leading models from OpenAI and Anthropic, I primarily use the new Cursor code editor. My goal is to not write any code myself; instead, I aim to use prompting and occasionally will make minor edits on my own.</p>
                    </div>
                    <div className="approach-item">
                        <h3>Problem Solving</h3>
                        <p>I aim to tackle problems that would have previously been too difficult or time-consuming to pursue on my own without AI assistance.</p>
                    </div>
                    <div className="approach-item">
                        <h3>Learning & Iteration</h3>
                        <p>Continuously experimenting with different AI models and approaches to understand their strengths and limitations.</p>
                    </div>
                </section>
                <section className="introduction">
                    <h2>About Me</h2>
                    <p>
                        I've been a software engineer for over 5 years and recently started on a journey to push the boundaries of what's possible with modern AI tools, building real-world applications to test and showcase their capabilities.
                    </p>
                </section>
            </main>
            <footer className="footer">
                <p>Let's Connect</p>
                <div className="footer-icons">
                    <a href="https://www.instagram.com/zaqmarks?igsh=MWttNGliZXdnMWluNA%3D%3D&utm_source=qr" target="_blank" rel="noopener noreferrer">
                        <FaInstagram className="instagram-icon" />
                    </a>
                    <a href="https://www.linkedin.com/in/zachary-marks-8b3a051ab?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app" target="_blank" rel="noopener noreferrer">
                        <FaLinkedin className="linkedin-icon" />
                    </a>
                </div>
            </footer>
        </div>
    );
}

export default LandingPage;
