// frontend/src/components/IconPalette.js

import React from 'react';
import DraggableIcon from './DraggableIcon';
import iconsData from '../../data/iconsData'; // Import the icons data

const IconPalette = ({ onStartCreate }) => {
  return (
    <div className="icon-palette">
      {iconsData.map((categoryData) => (
        <div key={categoryData.category} className="icon-category">
          <h3 className="category-title">{categoryData.category}</h3>
          <div className="icons-list">
            {categoryData.icons.map((iconName) => (
              <DraggableIcon
                key={iconName}
                name={iconName}
                onStartCreate={onStartCreate}
              />
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default IconPalette;
