// frontend/src/components/ConnectModal.js

import React, { useState } from 'react';
import Modal from 'react-modal';

// Set the app element for accessibility
Modal.setAppElement('#root');

const ConnectModal = ({ icons, fromIcon, onCreateConnection, onClose }) => {
  const [targetIconId, setTargetIconId] = useState('');
  const [direction, setDirection] = useState('start-to-end');
  const [connectionName, setConnectionName] = useState('');

  const availableIcons = icons.filter((icon) => icon.id !== fromIcon.id);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (targetIconId && connectionName.trim() !== '') {
      onCreateConnection(parseInt(targetIconId, 10), direction, connectionName.trim());
    } else {
      alert('Please select a target component and enter a connection name/type.');
    }
  };

  return (
    <Modal
      isOpen={true}
      onRequestClose={onClose}
      contentLabel="Create Connection"
      className="modal"
      overlayClassName="modal-overlay"
    >
      <h2>Create Connection</h2>
      <form onSubmit={handleSubmit}>
        <label>
          Connect from: <strong>{fromIcon.name}</strong>
        </label>
        <br />
        <label>
          Connect to:
          <select
            value={targetIconId}
            onChange={(e) => setTargetIconId(e.target.value)}
            required
          >
            <option value="">Select Component</option>
            {availableIcons.map((icon) => {
              const displayName =
                icon.name.length > 20 ? icon.name.substring(0, 20) + '...' : icon.name;
              return (
                <option key={icon.id} value={icon.id} title={icon.name}>
                  {displayName}
                </option>
              );
            })}
          </select>
        </label>
        <br />
        <label>
          Direction:
          <select
            value={direction}
            onChange={(e) => setDirection(e.target.value)}
          >
            <option value="start-to-end">Start to End</option>
            <option value="end-to-start">End to Start</option>
          </select>
        </label>
        <br />
        <label>
          Name/Type:
          <input
            type="text"
            value={connectionName}
            onChange={(e) => setConnectionName(e.target.value)}
            maxLength={20}
            placeholder="Enter connection name/type"
            required
          />
        </label>
        <br />
        <button type="submit">Create Connection</button>
        <button type="button" onClick={onClose} style={{ marginLeft: '10px' }}>
          Cancel
        </button>
      </form>
    </Modal>
  );
};

export default ConnectModal;
