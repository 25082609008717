// frontend/src/components/S3BucketMesh.js

import React from 'react';
import * as THREE from 'three';

const S3BucketMesh = ({ isHighlighted }) => {
  const bucketColor = isHighlighted ? '#32CD32' : '#32CD32'; // Green for both highlighted and non-highlighted states
  const rimColor = '#228B22';  // Darker green for rim contrast
  const handleColor = '#2E8B57'; // Handle green, but a bit different for variation

  return (
    <group>
      {/* Bucket body - smoother tapering */}
      <mesh castShadow receiveShadow>
        <cylinderGeometry args={[1.2, 1.0, 2.2, 32]} />
        <meshStandardMaterial color={bucketColor} side={THREE.DoubleSide} />
      </mesh>

      {/* Flat bottom */}
      <mesh position={[0, -1.1, 0]} castShadow receiveShadow>
        <cylinderGeometry args={[1.2, 1.2, 0.1, 32]} />
        <meshStandardMaterial color={bucketColor} />
      </mesh>

      {/* Thickened bucket rim */}
      <mesh position={[0, 1.1, 0]} castShadow receiveShadow>
        <torusGeometry args={[1.25, 0.15, 16, 100]} />
        <meshStandardMaterial color={rimColor} />
      </mesh>

      {/* Handle anchors - small bumps */}
      <mesh position={[-1.1, 1.0, 0]} castShadow receiveShadow>
        <sphereGeometry args={[0.1, 16, 16]} />
        <meshStandardMaterial color={handleColor} />
      </mesh>
      <mesh position={[1.1, 1.0, 0]} castShadow receiveShadow>
        <sphereGeometry args={[0.1, 16, 16]} />
        <meshStandardMaterial color={handleColor} />
      </mesh>

      {/* Handle - Curved downward and attached to anchors */}
      <mesh
        rotation={[Math.PI / 2, 0, 0]}
        position={[0, 1.1, 0]}
        castShadow
        receiveShadow
      >
        <torusGeometry args={[1.15, 0.06, 16, 100, Math.PI]} />
        <meshStandardMaterial color={handleColor} />
      </mesh>
    </group>
  );
};

export default S3BucketMesh;
