// frontend/src/components/CreatingIcon.js

import React, { useEffect } from 'react';
import { useThree } from '@react-three/fiber';
import { Text } from '@react-three/drei';
import * as THREE from 'three';
import IconMesh from '../Meshes/IconMesh';

const CreatingIcon = ({ creatingIcon, setCreatingIcon, setIcons }) => {
  const { camera, gl } = useThree();

  useEffect(() => {
    const handleMouseMove = (e) => {
      // Update the position of creatingIcon based on mouse position

      const rect = gl.domElement.getBoundingClientRect();
      const x = ((e.clientX - rect.left) / rect.width) * 2 - 1;
      const y = -((e.clientY - rect.top) / rect.height) * 2 + 1;

      const raycaster = new THREE.Raycaster();
      raycaster.setFromCamera({ x, y }, camera);
      const plane = new THREE.Plane(new THREE.Vector3(0, 1, 0), 0);
      const intersectPoint = new THREE.Vector3();
      raycaster.ray.intersectPlane(plane, intersectPoint);

      if (intersectPoint) {
        // Snap to grid
        const snapSize = 5;
        const snappedX = Math.round(intersectPoint.x / snapSize) * snapSize;
        const snappedZ = Math.round(intersectPoint.z / snapSize) * snapSize;

        setCreatingIcon((prevIcon) => ({
          ...prevIcon,
          position: [snappedX, 0.5, snappedZ],
        }));
      }
    };

    const handleClick = (e) => {
      // Finalize the icon addition
      setIcons((prevIcons) => [...prevIcons, creatingIcon]);
      setCreatingIcon(null);

      // Remove event listeners
      gl.domElement.removeEventListener('mousemove', handleMouseMove);
      gl.domElement.removeEventListener('click', handleClick);
    };

    gl.domElement.addEventListener('mousemove', handleMouseMove);
    gl.domElement.addEventListener('click', handleClick);

    return () => {
      gl.domElement.removeEventListener('mousemove', handleMouseMove);
      gl.domElement.removeEventListener('click', handleClick);
    };
  }, [camera, gl, creatingIcon, setCreatingIcon, setIcons]);

  // Render the icon at its current position
  return (
    <mesh position={creatingIcon.position}>
      <IconMesh icon={creatingIcon} isHighlighted={false} />
      {/* 2D Text Label for Creating Icon */}
      <Text
        position={[0, 0.01, 2]}
        rotation={[-Math.PI / 2, 0, 0]}
        fontSize={1}
        color="black"
        anchorX="center"
        anchorY="middle"
      >
        {creatingIcon.name}
      </Text>
    </mesh>
  );
};

export default CreatingIcon;
