import React, { useRef } from 'react';
import { useLoader } from '@react-three/fiber';
import { TextureLoader } from 'three';

const EC2Mesh = ({ isHighlighted }) => {
  const groupRef = useRef();
  const texture = useLoader(TextureLoader, '/icons/EC2.png'); // Updated path to match your project structure

  // Lighter colors
  const serverColor = isHighlighted ? '#78909C' : '#546E7A'; // Lighter blue-grey
  const frontPanelColor = '#607D8B'; // Changed from '#455A64' to a lighter shade
  const ledColor = isHighlighted ? '#4CAF50' : '#2E7D32'; // Green LED
  const ventColor = '#37474F'; // Lightened from '#263238'
  const rackMountColor = '#B0BEC5'; // Lightened from '#90A4AE'

  return (
    <group ref={groupRef} scale={1.5} position={[0, 0, -2.5]}>
      {/* Main Server Body */}
      <mesh position={[0, 0, 0]}>
        <boxGeometry args={[2, 0.4, 3]} />
        <meshStandardMaterial 
          color={serverColor}
          metalness={0.5} // Reduced metalness
          roughness={0.2} // Reduced roughness for more shine
          envMapIntensity={1.5} // Increased reflection
        />
      </mesh>

      {/* Front Panel - Inset */}
      <mesh position={[0, 0, 1.45]}>
        <boxGeometry args={[1.9, 0.35, 0.1]} />
        <meshStandardMaterial 
          color={frontPanelColor}
          metalness={0.3}    // Reduced from 0.6
          roughness={0.2}    // Reduced from 0.3
          emissive={frontPanelColor}
          emissiveIntensity={0.2}  // Added subtle self-illumination
          envMapIntensity={2}      // Increased environment reflection
        />
      </mesh>

      {/* Status LED */}
      <mesh position={[-0.8, 0, 1.51]}>
        <boxGeometry args={[0.05, 0.05, 0.02]} />
        <meshStandardMaterial 
          color={ledColor}
          emissive={ledColor}
          emissiveIntensity={1} // Brighter LED
        />
      </mesh>

      {/* Power LED */}
      <mesh position={[-0.8, 0.1, 1.51]}>
        <boxGeometry args={[0.05, 0.05, 0.02]} />
        <meshStandardMaterial 
          color="#2196F3"
          emissive="#2196F3"
          emissiveIntensity={1}
        />
      </mesh>

      {/* Ventilation Grid - Two rows */}
      {[0, 1].map((row) => (
        [...Array(12)].map((_, i) => (
          <mesh 
            key={`vent-${row}-${i}`} 
            position={[0.6 - i * 0.1, -0.05 + row * 0.1, 1.51]}
          >
            <boxGeometry args={[0.03, 0.05, 0.02]} />
            <meshStandardMaterial color={ventColor} />
          </mesh>
        ))
      ))}

      {/* Rack Mount Points */}
      {[-0.95, 0.95].map((x) => (
        [0.15, -0.15].map((y) => (
          <mesh key={`mount-${x}-${y}`} position={[x, y, 1.48]}>
            <cylinderGeometry args={[0.05, 0.05, 0.1, 8]} />
            <meshStandardMaterial color={rackMountColor} metalness={0.7} />
          </mesh>
        ))
      ))}

      {/* Handle Left */}
      <group position={[-0.9, 0, 1.48]}>
        <mesh>
          <boxGeometry args={[0.1, 0.2, 0.05]} />
          <meshStandardMaterial color={rackMountColor} metalness={0.6} />
        </mesh>
        {/* Handle grip */}
        <mesh position={[0, 0, 0.04]}>
          <boxGeometry args={[0.12, 0.22, 0.02]} />
          <meshStandardMaterial color={ventColor} roughness={0.8} />
        </mesh>
      </group>

      {/* Handle Right */}
      <group position={[0.9, 0, 1.48]}>
        <mesh>
          <boxGeometry args={[0.1, 0.2, 0.05]} />
          <meshStandardMaterial color={rackMountColor} metalness={0.6} />
        </mesh>
        {/* Handle grip */}
        <mesh position={[0, 0, 0.04]}>
          <boxGeometry args={[0.12, 0.22, 0.02]} />
          <meshStandardMaterial color={ventColor} roughness={0.8} />
        </mesh>
      </group>

      {/* Model Number/Label */}
      <mesh position={[0.4, 0, 1.51]}>
        <boxGeometry args={[0.4, 0.1, 0.01]} />
        <meshStandardMaterial color="#607D8B" roughness={0.9} /> // Updated to match frontPanelColor
      </mesh>

      {/* EC2 Logo - adjusted rotation and position */}
      <mesh 
        position={[0.7, 0.21, 1.2]} 
        rotation={[-Math.PI / 2, 0, 0]} // Rotate to lay flat on top
      >
        <planeGeometry args={[0.4, 0.4]} />
        <meshStandardMaterial 
          map={texture}
          transparent={true}
          opacity={0.9}
        />
      </mesh>
    </group>
  );
};

export default EC2Mesh;
