import React, { useRef, useMemo } from 'react';
import { useFrame, useLoader } from '@react-three/fiber';
import * as THREE from 'three';

const CloudFrontMesh = ({ isHighlighted }) => {
  const globeRef = useRef();
  const spotsRef = useRef([]);
  const lightsRef = useRef([]);

  // Load Earth and CloudFront textures
  const earthTexture = useLoader(THREE.TextureLoader, '/textures/Earth.png');
  const cloudFrontTexture = useLoader(THREE.TextureLoader, '/icons/CloudFront.png'); // Load CloudFront texture

  // Colors
  const spotColor = '#FFD700'; // Gold for glowing spots

  // Create glowing spots
  const spotPositions = useMemo(() => {
    const positions = [];
    for (let i = 0; i < 15; i++) {
      const phi = Math.acos(-1 + (2 * i) / 15);
      const theta = Math.sqrt(15 * Math.PI) * phi;
      positions.push([
        Math.cos(theta) * Math.sin(phi),
        Math.sin(theta) * Math.sin(phi),
        Math.cos(phi),
      ]);
    }
    return positions;
  }, []);

  // Animation for glowing effect
  useFrame((state) => {
    spotsRef.current.forEach((spot, index) => {
      const scale = 0.4 + Math.sin(index + state.clock.getElapsedTime() * 2) * 0.1;
      spot.scale.set(scale, scale, scale);
      spot.material.opacity = 0.6 + Math.sin(index + state.clock.getElapsedTime() * 2) * 0.3;
    });

    lightsRef.current.forEach((light, index) => {
      light.intensity = 1.5 + Math.sin(index + state.clock.getElapsedTime() * 2) * 0.5;
    });
  });

  return (
    <group ref={globeRef}>
      {/* Globe */}
      <mesh>
        <sphereGeometry args={[1, 64, 64]} />
        <meshStandardMaterial map={earthTexture} />
      </mesh>

      {/* Glowing spots with pulsing lights */}
      {spotPositions.map((pos, index) => (
        <group key={index} position={pos}>
          <mesh ref={(el) => (spotsRef.current[index] = el)} scale={[0.1, 0.1, 0.1]}>
            <sphereGeometry args={[0.1, 16, 16]} />
            <meshBasicMaterial color={spotColor} transparent opacity={0.8} />
          </mesh>
          <pointLight
            ref={(el) => (lightsRef.current[index] = el)}
            color={spotColor}
            intensity={1.5}
            distance={2.5}
          />
        </group>
      ))}

      {/* Fixed 2D CloudFront Logo on the grid */}
      <mesh position={[2.5, 0.01, 1.5]} rotation={[-Math.PI / 2, 0, 0]}>
        <planeGeometry args={[0.8, 0.8]} />
        <meshBasicMaterial map={cloudFrontTexture} transparent side={THREE.DoubleSide} />
      </mesh>
    </group>
  );
};

export default CloudFrontMesh;
