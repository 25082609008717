import React, { useRef, useMemo } from 'react';
import { useFrame, useLoader } from '@react-three/fiber';
import { Text } from '@react-three/drei';
import * as THREE from 'three';

const SQSMesh = ({ isHighlighted }) => {
  const groupRef = useRef();
  const messagesRef = useRef();

  const queueLength = 4; // Reduced from 6 to 4
  const queueHeight = 0.2; // Increased from 0.1 to 0.2
  const queueWidth = 0.5;  // New constant for queue width
  const messageWidth = 0.4;
  const messageHeight = 0.3;
  const messageCount = 10;

  const queueColor = '#808080'; // Gray color for the queue line
  const messageColors = {
    new: '#00FF00',     // Green for new messages
    processing: '#FFFF00', // Yellow for in-process messages
    completed: '#0000FF'   // Blue for completed messages
  };

  // Load SQS logo texture
  const texture = useLoader(THREE.TextureLoader, '/icons/SQS.png');

  // Create queue line, messages, and logo
  const { queueLine, messages, logoMesh } = useMemo(() => {
    // Create queue line (now wider)
    const lineGeometry = new THREE.BoxGeometry(queueLength, queueHeight, queueWidth);
    const lineMaterial = new THREE.MeshStandardMaterial({ color: queueColor });
    const queueLine = new THREE.Mesh(lineGeometry, lineMaterial);

    // Create messages
    const messageGeometry = new THREE.BoxGeometry(messageWidth, messageHeight, 0.05);
    const messages = new Array(messageCount).fill().map(() => {
      const messageMaterial = new THREE.MeshStandardMaterial({
        color: messageColors.new,
        transparent: true,
        opacity: 0.8,
      });
      const message = new THREE.Mesh(messageGeometry, messageMaterial);
      
      // Add "text lines" to the message
      const lines = new THREE.Object3D();
      for (let i = 0; i < 3; i++) {
        const line = new THREE.Mesh(
          new THREE.PlaneGeometry(messageWidth * 0.8, messageHeight * 0.05),
          new THREE.MeshBasicMaterial({ color: '#000000' })
        );
        line.position.y = (i - 1) * messageHeight * 0.25;
        line.position.z = 0.026; // Slightly in front of the message
        lines.add(line);
      }
      message.add(lines);
      return message;
    });

    // Create logo mesh
    const logoGeometry = new THREE.PlaneGeometry(1, 1);
    const logoMaterial = new THREE.MeshBasicMaterial({ 
      map: texture,
      transparent: true,
      side: THREE.DoubleSide
    });
    const logoMesh = new THREE.Mesh(logoGeometry, logoMaterial);

    return { queueLine, messages, logoMesh };
  }, [queueColor, texture]);

  // Animation for messages
  useFrame((state, delta) => {
    if (messagesRef.current) {
      messagesRef.current.children.forEach((message, index) => {
        message.position.x += 0.005;
        if (message.position.x > queueLength / 2 + messageWidth / 2) {
          message.position.x = -queueLength / 2 - messageWidth / 2;
          // Randomly change message color to simulate state changes
          const colorKeys = Object.keys(messageColors);
          const randomColor = messageColors[colorKeys[Math.floor(Math.random() * colorKeys.length)]];
          message.material.color.setStyle(randomColor);
        }
      });
    }
  });

  return (
    <group ref={groupRef}>
      {/* Queue Line */}
      <primitive object={queueLine} position={[0, 0, 0]} />

      {/* Messages */}
      <group ref={messagesRef}>
        {messages.map((message, index) => (
          <primitive
            key={index}
            object={message}
            position={[
              (index / (messageCount - 1) - 0.5) * (queueLength - messageWidth),
              queueHeight / 2 + messageHeight / 2, // Align to top of queue
              0 // Center on the queue width
            ]}
          />
        ))}
      </group>

      {/* SQS Logo */}
      <primitive 
        object={logoMesh} 
        position={[-queueLength / 2, 0, queueWidth + 0.5]}
        rotation={[-Math.PI / 2, 0, 0]}
        scale={[0.5, 0.5, 0.5]}
      />

      {/* Input and Output labels */}
      <Text
        position={[-queueLength / 2 - 0.3, queueHeight + 0.2, 0]}
        rotation={[0, 0, 0]}
        fontSize={0.2}
        color={'#000000'}
      >
        Input
      </Text>
      <Text
        position={[queueLength / 2 + 0.3, queueHeight + 0.2, 0]}
        rotation={[0, 0, 0]}
        fontSize={0.2}
        color={'#000000'}
      >
        Output
      </Text>
    </group>
  );
};

export default SQSMesh;
