import React, { useRef } from 'react';
import { useFrame } from '@react-three/fiber';

const ComputerMesh = ({ isHighlighted }) => {
  const groupRef = useRef();
  const screenRef = useRef(); // Ref for the screen

  // Colors
  const bodyColor = isHighlighted ? '#E0E0E0' : '#D0D0D0'; // Light silver for body
  const screenColor = '#000000'; // Black for screen
  const keyboardColor = '#CCCCCC'; // Light gray for keyboard
  const keyColor = '#B0B0B0';

  useFrame((state, delta) => {
    if (screenRef.current) {
      // Animate the screen to open and close with reversed rotation limits
      const time = state.clock.getElapsedTime();
      const angle = Math.sin(time * 0.5);
      
      // Limit the opening to 90 degrees (PI/2 radians) and allow closing to 45 degrees (PI/4 radians)
      const rotationX = angle > 0 
        ? angle * (Math.PI / 2) // Opening: max 90 degrees
        : angle * (Math.PI / 4); // Closing: max -45 degrees

      screenRef.current.rotation.x = rotationX;
    }
  });

  return (
    <group ref={groupRef} scale={2}>
      {/* Laptop Base */}
      <mesh position={[0, 0.015, 0]}>
        <boxGeometry args={[1.2, 0.03, 0.8]} />
        <meshPhongMaterial color={bodyColor} />
      </mesh>

      {/* Rounded corners for base */}
      {[[-0.6, 0.4], [0.6, 0.4], [-0.6, -0.4], [0.6, -0.4]].map((pos, index) => (
        <mesh key={index} position={[pos[0], 0.015, pos[1]]}>
          <cylinderGeometry args={[0.015, 0.015, 0.03, 16, 1, false, Math.PI / 2, Math.PI]} />
          <meshPhongMaterial color={bodyColor} />
        </mesh>
      ))}

      {/* Laptop Screen */}
      <group ref={screenRef} position={[0, 0.03, -0.4]} rotation={[0, 0, 0]}>
        <mesh position={[0, 0.4, 0]}>
          <boxGeometry args={[1.2, 0.8, 0.015]} />
          <meshPhongMaterial color={bodyColor} />
        </mesh>
        <mesh position={[0, 0.4, 0.008]}>
          <planeGeometry args={[1.15, 0.75]} />
          <meshPhongMaterial color={screenColor} />
        </mesh>
      </group>

      {/* Rounded corners for screen */}
      {[[-0.6, 0.4], [0.6, 0.4], [-0.6, -0.4], [0.6, -0.4]].map((pos, index) => (
        <mesh key={index} position={[pos[0], 0.43 + pos[1] * Math.cos(Math.PI / 6), -0.4 + pos[1] * Math.sin(Math.PI / 6)]} rotation={[-Math.PI / 6, 0, 0]}>
          <cylinderGeometry args={[0.0075, 0.0075, 0.015, 16, 1, false, Math.PI / 2, Math.PI]} />
          <meshPhongMaterial color={bodyColor} />
        </mesh>
      ))}

      {/* Keyboard */}
      <mesh position={[0, 0.031, 0]}>
        <boxGeometry args={[1.1, 0.001, 0.6]} />
        <meshPhongMaterial color={keyboardColor} />
      </mesh>

      {/* Keyboard Keys */}
      {Array(6).fill().map((_, row) => 
        Array(14).fill().map((_, col) => (
          <mesh key={`key-${row}-${col}`} position={[-0.5 + col * 0.075, 0.032, -0.25 + row * 0.09]}>
            <boxGeometry args={[0.065, 0.001, 0.08]} />
            <meshPhongMaterial color={keyColor} />
          </mesh>
        ))
      )}

      {/* Trackpad */}
      <mesh position={[0, 0.031, 0.25]}>
        <boxGeometry args={[0.45, 0.001, 0.3]} />
        <meshPhongMaterial color={bodyColor} />
      </mesh>

      {/* Trackpad subtle border */}
      <mesh position={[0, 0.0315, 0.25]}>
        <ringGeometry args={[0.224, 0.225, 32]} />
        <meshBasicMaterial color={keyColor} />
      </mesh>
    </group>
  );
};

export default ComputerMesh;
