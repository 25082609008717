// frontend/src/components/Modals/ChangeIconModal.js

import React, { useState } from 'react';
import Modal from 'react-modal';
import iconsData from '../../data/iconsData'; // Import the icons data

// Set the app element for accessibility
Modal.setAppElement('#root');

const ChangeIconModal = ({ isOpen, onRequestClose, iconToChange, onChangeIcon }) => {
  const [selectedIconType, setSelectedIconType] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    if (selectedIconType) {
      onChangeIcon(selectedIconType);
    } else {
      alert('Please select a component.');
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Change Component"
      className="modal"
      overlayClassName="modal-overlay"
    >
      <h2>Change Component</h2>
      <form onSubmit={handleSubmit}>
        <label>
          Current Component: <strong>{iconToChange.name}</strong>
        </label>
        <br />
        <label>
          Select New Component:
          <select
            value={selectedIconType}
            onChange={(e) => setSelectedIconType(e.target.value)}
            required
          >
            <option value="">Select Component</option>
            {iconsData.map((category) => (
              <optgroup key={category.category} label={category.category}>
                {category.icons.map((iconName) => (
                  <option key={iconName} value={iconName}>
                    {iconName}
                  </option>
                ))}
              </optgroup>
            ))}
          </select>
        </label>
        <br />
        <button type="submit">Change Component</button>
        <button type="button" onClick={onRequestClose} style={{ marginLeft: '10px' }}>
          Cancel
        </button>
      </form>
    </Modal>
  );
};

export default ChangeIconModal;
