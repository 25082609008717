// src/components/Animations/AuthenticationAnimation.js

import React, { useRef } from 'react';
import { useFrame } from '@react-three/fiber';
import { Text, Line } from '@react-three/drei';
import * as THREE from 'three';

const AuthenticationAnimation = ({ position = [0, 0, 0] }) => {
  const groupRef = useRef();
  const scanLineRef = useRef();

  const animationProgress = useRef(0);

  // Adjusted duration to match the presentation step duration
  const duration = 4; // Total animation duration in seconds

  useFrame((state, delta) => {
    animationProgress.current += delta;
    const t = (animationProgress.current % duration) / duration;

    if (scanLineRef.current) {
      // Move the scan line from top to bottom over the duration
      scanLineRef.current.position.y = THREE.MathUtils.lerp(0.4, -0.4, t);

      // Adjust the opacity for a fading effect
      scanLineRef.current.material.opacity =
        Math.sin(t * Math.PI) * 0.5 + 0.5;
    }
  });

  // Simple person silhouette points
  const silhouettePoints = [
    new THREE.Vector3(-0.05, -0.1, 0),
    new THREE.Vector3(0.05, -0.1, 0), // legs
    new THREE.Vector3(0, -0.1, 0),
    new THREE.Vector3(0, 0.05, 0), // body
    new THREE.Vector3(-0.05, 0.05, 0),
    new THREE.Vector3(0.05, 0.05, 0), // arms
    new THREE.Vector3(0, 0.05, 0),
    new THREE.Vector3(0, 0.15, 0), // neck
    new THREE.Vector3(-0.04, 0.15, 0),
    new THREE.Vector3(0.04, 0.15, 0),
    new THREE.Vector3(0, 0.23, 0),
    new THREE.Vector3(-0.04, 0.15, 0), // head
  ];

  return (
    <group ref={groupRef} position={position}>
      {/* Card base */}
      <mesh position={[0, 0, -0.025]}>
        <boxGeometry args={[1.6, 1, 0.05]} />
        <meshPhongMaterial color="#1a237e" />
      </mesh>

      {/* Card face */}
      <mesh position={[0, 0, 0]}>
        <boxGeometry args={[1.5, 0.9, 0.02]} />
        <meshStandardMaterial color="#ffffff" />
      </mesh>

      {/* Photo placeholder */}
      <mesh position={[-0.55, 0, 0.02]}>
        <boxGeometry args={[0.3, 0.4, 0.01]} />
        <meshStandardMaterial color="#e0e0e0" />
      </mesh>

      {/* Person silhouette */}
      <group position={[-0.55, 0, 0.03]}>
        <Line
          points={silhouettePoints}
          color="#37474f"
          lineWidth={2}
        />
      </group>

      {/* ID card details */}
      <Text
        position={[0.1, 0.2, 0.02]}
        fontSize={0.09}
        color="#263238"
        anchorX="left"
        anchorY="middle"
      >
        John Doe
      </Text>
      <Text
        position={[0.1, 0, 0.02]}
        fontSize={0.06}
        color="#37474f"
        anchorX="left"
        anchorY="middle"
      >
        ID: 123-456-789
      </Text>
      <Text
        position={[0.1, -0.2, 0.02]}
        fontSize={0.06}
        color="#37474f"
        anchorX="left"
        anchorY="middle"
      >
        Access Level: Alpha
      </Text>

      {/* Scanning line effect */}
      <mesh ref={scanLineRef} position={[0, 0.4, 0.03]}>
        <boxGeometry args={[1.5, 0.05, 0.01]} />
        <meshBasicMaterial
          color="#4caf50"
          transparent
          opacity={0.7}
        />
      </mesh>
    </group>
  );
};

export default AuthenticationAnimation;
